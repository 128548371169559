import axios from 'axios'
import React, { useEffect } from 'react';
import { Grid, TextField, Tooltip, Typography, Divider, InputLabel, Button } from '@mui/material';
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import { TAGLINE_RAZOR_PAY_ENV_TEST_API_KEY, TAGLINE_RAZOR_PAY_ENV_TEST_API_SECRET_KEY } from '../../../config/razorPayKeysConfig';
function RazorPayPaymentScreen() {
    const [responseId, setResponseId] = React.useState("");
    const [responseState, setResponseState] = React.useState([]);

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");

            script.src = src;

            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }

            document.body.appendChild(script);
        })
    }

    const createRazorpayOrder = (amount) => {
        console.log('Exec createRazorpayOrder : ', amount)

        let data = JSON.stringify({
            amount: amount * 100,
            currency: "INR"
        })

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "/api/pg-razorpay/order",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data))
                handleRazorpayScreen(response.data.amount)
            })
            .catch((error) => {
                console.log("error at", error)
            })
    }

    const handleRazorpayScreen = async (amount) => {
        const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js")

        if (!res) {
            alert("Some error at razorpay screen loading")
            return;
        }

        const options = {
            key: TAGLINE_RAZOR_PAY_ENV_TEST_API_KEY,
            amount: amount,
            currency: 'INR',
            name: "Tagline Traders",
            description: "payment to Tagline Traders",
            image: "https://papayacoders.com/demo.png",
            handler: function (response) {
                setResponseId(response.razorpay_payment_id)
            },
            prefill: {
                name: "Tagline Traders",
                email: "appsdevpreneur247@gmail.com"
            },
            theme: {
                color: "#F4C430"
            }
        }

        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }

    const paymentFetch = (e) => {
        e.preventDefault();

        const paymentId = e.target.paymentId.value;

        axios.get(`/api/pg-razorpay/payment/${paymentId}`)
            .then((response) => {
                console.log(response.data);
                setResponseState(response.data)
            })
            .catch((error) => {
                console.log("error occures", error)
            })
    }

    return (
        <>
            <Card style = {{width:'50%'}}>
                <Typography
                    variant="h6"
                    style={{ margin: "0.5rem" , textAlign:"center"}}
                    color="black"
                >
                    Testing Razor Pay
                    <Divider></Divider>
                </Typography>
                <CardBody style={{ margin: "0.5rem" , textAlign:"center"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                            onClick={()=>createRazorpayOrder(100)}
                          >
                            <Typography
                              style={{
                                margin: "0.5rem",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                                fontFamily: 'Montserrat, Arial, sans-serif',
                                fontWeight: "600",
                                fontSize: "0.85rem"
                              }}
                            >Pay 100 INR</Typography>
                            {/* Proceed to Buy */}
                          </Button>

                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            {responseId && <p>{responseId}</p>}
                            <p>This is payment verification form</p>
                            <form onSubmit={paymentFetch}>
                                <TextField type="text" name="paymentId" /> <p/>
                                <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px", marginTop: "1rem" }}
                            onClick={()=>createRazorpayOrder(100)}
                          >
                            <Typography
                              style={{
                                margin: "0.5rem",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                                fontFamily: 'Montserrat, Arial, sans-serif',
                                fontWeight: "600",
                                fontSize: "0.85rem"
                              }}
                            >Fetch Payment</Typography>
                            {/* Proceed to Buy */}
                          </Button>
                                {/* <Button type="submit">Fetch Payment</Button> */}
                                <p>
                                {responseState.length !== 0 && (
                                    <ul>
                                        <li>Amount: {responseState.amount / 100} Rs.</li>
                                        <li>Currency: {responseState.currency}</li>
                                        <li>Status: {responseState.status}</li>
                                        <li>Method: {responseState.method}</li>
                                    </ul>
                                )}</p>
                            </form>
                        </Grid>
                    </Grid>
                </CardBody>
            </Card>
        </>
    );
}

export default RazorPayPaymentScreen;