export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_LIST_ALL_REQUEST = "PRODUCT_LIST_ALL_REQUEST";
export const PRODUCT_LIST_ALL_SUCCESS = "PRODUCT_LIST_ALL_SUCCESS";
export const PRODUCT_LIST_ALL_FAIL = "PRODUCT_LIST_ALL_FAIL";

export const PRODUCT_LIST_BY_SUB_CATEGORY_REQUEST =
  "PRODUCT_LIST_BY_SUB_CATEGORY_REQUEST";
export const PRODUCT_LIST_BY_SUB_CATEGORY_SUCCESS =
  "PRODUCT_LIST_BY_SUB_CATEGORY_SUCCESS";
export const PRODUCT_LIST_BY_SUB_CATEGORY_FAIL =
  "PRODUCT_LIST_BY_SUB_CATEGORY_FAIL";

export const PRODUCT_LIST_BY_CATEGORY_REQUEST =
  "PRODUCT_LIST_BY_CATEGORY_REQUEST";
export const PRODUCT_LIST_BY_CATEGORY_SUCCESS =
  "PRODUCT_LIST_BY_CATEGORY_SUCCESS";
export const PRODUCT_LIST_BY_CATEGORY_FAIL = "PRODUCT_LIST_BY_CATEGORY_FAIL";

export const PRODUCT_LIST_BY_CATEGORY_ID_REQUEST =
  "PRODUCT_LIST_BY_CATEGORY_ID_REQUEST";
export const PRODUCT_LIST_BY_CATEGORY_ID_SUCCESS =
  "PRODUCT_LIST_BY_CATEGORY_ID_SUCCESS";
export const PRODUCT_LIST_BY_CATEGORY_ID_FAIL =
  "PRODUCT_LIST_BY_CATEGORY_ID_FAIL";

export const PRODUCT_DETAILS_BY_SUBCATEGORY_ID_PRODUCT_ID_REQUEST =
  "PRODUCT_DETAILS_BY_SUBCATEGORY_ID_PRODUCT_ID_REQUEST";
export const PRODUCT_DETAILS_BY_SUBCATEGORY_ID_PRODUCT_ID_SUCCESS =
  "PRODUCT_DETAILS_BY_SUBCATEGORY_ID_PRODUCT_ID_SUCCESS";
export const PRODUCT_DETAILS_BY_SUBCATEGORY_ID_PRODUCT_ID_FAIL =
  "PRODUCT_DETAILS_BY_SUBCATEGORY_ID_PRODUCT_ID_FAIL";

export const PRODUCT_DETAILS_BY_PRODUCT_ID_REQUEST =
  "PRODUCT_DETAILS_BY_PRODUCT_ID_REQUEST";
export const PRODUCT_DETAILS_BY_PRODUCT_ID_SUCCESS =
  "PRODUCT_DETAILS_BY_PRODUCT_ID_SUCCESS";
export const PRODUCT_DETAILS_BY_PRODUCT_ID_FAIL =
  "PRODUCT_DETAILS_BY_PRODUCT_ID_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

export const PRODUCT_TOP_REQUEST = "PRODUCT_TOP_REQUEST";
export const PRODUCT_TOP_SUCCESS = "PRODUCT_TOP_SUCCESS";
export const PRODUCT_TOP_FAIL = "PRODUCT_TOP_FAIL";

export const PRODUCT_LIST_BEST_SELLER_REQUEST =
  "PRODUCT_LIST_BEST_SELLER_REQUEST";
export const PRODUCT_LIST_BEST_SELLER_SUCCESS =
  "PRODUCT_LIST_BEST_SELLER_SUCCESS";
export const PRODUCT_LIST_BEST_SELLER_FAIL = "PRODUCT_LIST_BEST_SELLER_FAIL";

export const PRODUCT_VISIBILITY_UPDATE_REQUEST = "PRODUCT_VISIBILITY_UPDATE_REQUEST";
export const PRODUCT_VISIBILITY_UPDATE_SUCCESS = "PRODUCT_VISIBILITY_UPDATE_SUCCESS";
export const PRODUCT_VISIBILITY_UPDATE_FAIL = "PRODUCT_VISIBILITY_UPDATE_FAIL";
export const PRODUCT_VISIBILITY_UPDATE_RESET = "PRODUCT_VISIBILITY_UPDATE_RESET";
