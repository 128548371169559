import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  Button,
  Tooltip,
  Typography
} from "@mui/material";
import { listWarehousePurchaseOrderById } from "../../../actions/warehousePurchaseOrderAction";
import { listStorePurchaseOrderById , updateStorePurchaseOrderQuantityById } from "../../../actions/storePurchaseOrderAction";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import RenderOrderSummary from "./RenderOrderSummary";
import { formatToLocaleCurrency } from "../../../util/Utility.js";
import { Save } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainContainer: {
    marginTop: "4em",
    [theme.breakpoints.down("md")]: {
      marginTop: "3em",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2em",
    },
  },
  heroTextContainer: {
    minWidth: "21em",
    maxWidth: "50em",
    // marginLeft: "1em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      maxWidth: "30em",
      marginTop: "1em",
    },
  },
  animation: {
    maxWidth: "50em",
    minWidth: "21em",
    marginTop: "-6%",
    // marginBottom: "-6%",
    marginLeft: "1%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      maxWidth: "30em",
      marginTop: "1em",
    },
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },

  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  imageIcon: {
    height: "1rem",
  },
  iconRoot: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const PurchaseOrderProductDetails = () => {
  const classes = useStyles();
  const { id, warehouseOrStore } = useParams();
  console.log(`Purchase Order ID : , ${id}, warehouseOrStore :  ${warehouseOrStore}`);
  const [rowData, setRowData] = useState(() => []);
  const [preEditRowData, setPreEditRowData] = useState(() => []);
  const [edited, setEdited] = useState(() => false);

  const warehousePurchaseOrderById = useSelector((state) => state.warehousePurchaseOrderById);
  const { wh_po_data, wh_po_error, wh_po_loading } = warehousePurchaseOrderById;
  const storePurchaseOrderById = useSelector((state) => state.storePurchaseOrderById);
  const { store_po_by_id_loading, store_po_by_id_data, store_po_by_id_error } = storePurchaseOrderById;

  const gridRef = useRef();

  const onCellValueChanged = (params) => {
    const updatedRowData = params.data;
    setEdited(()=>true)
    console.log('params : ', params)
    console.log('Updated Row Data OLD :', updatedRowData);
    console.log('params.data.Qty:', params.data.Qty);
    const updatedRow = {
      ...params.data,
      Total: (params.data.Qty ? Number(params.data.Qty) : 0) * (params.data.Price ? Number(params.data.Price) : 0),
    };
    console.log('Updated Row Data LATEST Total Calculation :', updatedRow);
    const newData = rowData.map(row => {
      return row.ProductName === updatedRow.ProductName ? updatedRow : row
    }
    );
    setRowData((prevState) => newData);
  };

  const dispatch = useDispatch();
  const columnDefs = [
    {
      field: '#',
      width: 100,
      cellStyle: {
        textAlign: 'center'
      },
    },
    {
      field: 'ProductName',
      width: 400,
      filter: true,
      cellStyle: {
        textAlign: 'left'
      },
    },
    {
      field: 'Qty',
      width: 150,
      editable: true,
      cellStyle: (params) => {
        return {
          textAlign: 'right',
          color: 'black',
          backgroundColor: 'white',
          borderRadius: '5px',
          borderColor: 'white'
        };
      },
    },
    {
      field: 'Price',
      width: 150,
      cellStyle: {
        textAlign: 'right'
      },
    },
    {
      field: 'Mrp',
      width: 150,
      cellStyle: {
        textAlign: 'right'
      },
    },
    {
      field: 'Tax',
      headerName: "Tax",
      width: 150,
      cellStyle: {
        textAlign: 'right'
      },
    },
    {
      field: "TaxAmount",
      headerName: "Tax Amount",
      width: 150,
      cellStyle: {
        textAlign: 'right'
      },
    },
    {
      field: 'Total',
      width: 200,
      cellStyle: {
        textAlign: 'right'
      },
    },
  ]

  const defaultColDef = useMemo(() => ({
    filter: false,
    floatingFilter: false,
    resizable: true,
  }), []
  )

  useEffect(() => {
    if (id) {
      dispatch(listStorePurchaseOrderById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    console.log('Exec UseEffect to populatePurchaseOrdersRowData')
    if(store_po_by_id_data){
      populatePurchaseOrdersRowData()
      setPreEditRowData(()=>store_po_by_id_data)
    }else{
      console.log('No Data Found')
    }
  }, [store_po_by_id_data]);

  const populatePurchaseOrdersRowData = () => {
    console.log("populate PurchaseOrdersRowData for Store...! ", store_po_by_id_data)
    setRowData(() => store_po_by_id_data && store_po_by_id_data.purchaseOrderProducts && store_po_by_id_data.purchaseOrderProducts.map((prd, index) => {
      return {
        '#': index + 1,
        ProductName: prd.productName,
        Qty: prd.qty ? formatToLocaleCurrency(Number(prd.qty)) : 0,
        Price: prd.pricePerUnit ? formatToLocaleCurrency(Number(prd.pricePerUnit)) : 0,
        Mrp: prd.mrp ? formatToLocaleCurrency(Number(prd.mrp)) : 0,
        Tax: prd.tax ? formatToLocaleCurrency(Number(prd.tax)) : 0,
        TaxAmount: formatToLocaleCurrency(Math.ceil((((Number(prd.price) * Number(prd.qty)) * 100) / (100 + Number(prd.tax))) * (Number(prd.tax) / 100))),
        Total: prd.total ? formatToLocaleCurrency(Number(prd.total)) : 0,
      }
    }));
  }

  const renderPurchaseOrderHeader = () => {
    return (<React.Fragment>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Tooltip title="Back" arrow>
              <ArrowBackIcon fontSize="medium" onClick={() => window.history.back()} style={{ float: "left", marginRight: "3em" }} />
            </Tooltip>
            <Typography variant="h5" style={{ fontWeight: 500 }}>
              Purchase Order Details
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </React.Fragment>
    )
  }
  const updateHandler = ()=>{
    console.log('Exec updateHandler ...')
    const obj = {id : id, purchaseOrderItems : rowData, preEditRowData : preEditRowData}
    dispatch(updateStorePurchaseOrderQuantityById(obj))
  }

  return (
    <React.Fragment>
      {store_po_by_id_loading&& <CustomBackdropSpinner />}
      <GridContainer>
        {renderPurchaseOrderHeader ? renderPurchaseOrderHeader : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <div className="ag-theme-quartz" style={{ width: "75%", height: '55vh', margin: 'auto' }}>
                <AgGridReact
                  // ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onCellValueChanged={onCellValueChanged}
                >
                </AgGridReact>
              </div>
            </CardBody>
          </Card>
        </GridItem>
        {rowData && rowData.length > 0 && store_po_by_id_data && <RenderOrderSummary currentData={rowData} storeDate={store_po_by_id_data} />}
        {edited && <GridItem xs={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center", marginTop: "0.5rem", marginBottom: "0.5rem" }}>

          <Tooltip title="Update Purchase Order" arrow>
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              onClick={updateHandler}
              style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
            >
              <Save
                fontSize="medium"
                style={{ float: "left", marginRight: "10px" }}
              />
              Update
            </Button>
          </Tooltip>
        </GridItem>}
      </GridContainer>
    </React.Fragment>
  );
}

export default PurchaseOrderProductDetails;