import React, { useState, useEffect } from "react";
import {
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Button,
    Tooltip,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Icon,
    Typography,
    TextField,
} from "@mui/material";
import Message from "../../../Message";
import rupeeSvgIcon from "../../../../assets/images/currency-inr.svg";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import CustomizedDialogs from "../../../Dialog/CustomizedDialogs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import { payment_mode_single_multiple, payment_types } from "../../../../data/index";
import GridItem from "../../../Grid/GridItem";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { updateProductStockPostPlacingOrder } from "../../../../actions/stockAction";
import { createOrder } from "../../../../actions/orderAction";
import { ORDER_CREATE_RESET } from "../../../../constants/orderConstants";
import { createInvoice } from "../../../../actions/vehicleSalesInvoiceAction";
import { VEHICLE_INVOICE_TO_SHOP_CREATE_RESET, VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_RESET } from "../../../../constants/vehicleSalesInvoiceConstants";
import { updateStockWhenOutFromVehicleNoAndProductName } from "../../../../actions/vehicleStockAction";
import GridContainer from "../../../Grid/GridContainer";
import CardHeader from "../../../Card/CardHeader";
import { updateStockOutByWarehouseProduct } from "../../../../actions/warehouseStockAction";
import { updateStockOutByStoreProduct } from "../../../../actions/storeStockAction";
import { createWalkinSaleInvoice } from "../../../../actions/walkinSaleInvoiceAction";
import { createVehicleSalesInvoice } from "../../../../actions/vehicleSalesInvoiceAction";
import { WALKIN_SALES_INVOICE_CREATE_RESET } from "../../../../constants/walkinSalesInvoiceConstants";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { addDecimals, formatToLocaleCurrency } from "../../../../util/Utility";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../../assets/images/green_tick.png";
import axios from 'axios'
import {
    TAGLINE_RAZOR_PAY_ENV_TEST_API_KEY, TAGLINE_RAZOR_PAY_ENV_TEST_API_SECRET_KEY,
    TAGLINE_RAZOR_PAY_ENV_LIVE_API_KEY, TAGLINE_RAZOR_PAY_ENV_LIVE_API_SECRET_KEY
} from '../../../../config/razorPayKeysConfig';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        justifyContent: "center",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },

    control: {
        padding: theme.spacing(2),
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleGreen: {
        color: "#26A541",
        marginTop: "0px",
        minHeight: "auto",
        fontFamily: "Roboto",
        marginBottom: "3px",
        textDecoration: "none",
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        textAlign: "left",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
}));

const VehicleSalesPayment = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [responseId, setResponseId] = React.useState("");
    const { invoiceDetails, productsInCart, vehicleNo, customerDetails, paymentDetails } = props
    console.log('invoiceDetails : ', invoiceDetails, "productsInCart : ", productsInCart, "customerDetails : ", customerDetails, ' paymentDetails : ', paymentDetails)
    const [isCash, setIsCash] = useState(() => false);
    const [isUpi, setIsUpi] = useState(() => false);
    const [isCashRadioItem, setIsCashRadioItem] = useState(() => false);
    const [isUpiRadioItem, setIsUpiRadioItem] = useState(() => false);
    const [paymentMode, setPaymentMode] = useState(() => "Single");
    const [paymentType, setPaymentType] = useState(() => []);
    const [cashAmount, setCashAmount] = useState(() => 0.0);
    const [upiAmount, setUpiAmount] = useState(() => 0.0);
    const [amountPaid, setAmountPaid] = useState(() => 0.0);
    const [paidTo, setPaidTo] = useState(() => "");
    const [itemsDiscountPrice, setItemsDiscountPrice] = useState(() => null);
    const [balanceAmountTobePaid, setBalanceAmountTobePaid] = useState(() => 0.0);
    const vertical = 'top';
    const horizontal = 'center';
    const [customError, setCustomError] = useState(() => []);

    const [errorObject, setErrorObject] = useState(() => null);
    const [itemsPriceObject, setItemsPriceObject] = useState(() => { });
    const [checked, setChecked] = React.useState(false);
    let renderCashDetails = "";
    let paymentMadeDetails = {};

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script);
        })
    }

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const [open, setOpen] = React.useState(false);

    const handleRazorpayScreen = async (amount) => {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

        if (!res) {
            alert("Some error at razorpay screen loading")
            return;
        }

        const options = {
            //   key: TAGLINE_RAZOR_PAY_ENV_TEST_API_KEY,
            key: TAGLINE_RAZOR_PAY_ENV_LIVE_API_KEY,
            amount: amount,
            currency: 'INR',
            name: "Tagline Traders",
            description: "payment to Tagline Traders",
            image: "https://papayacoders.com/demo.png",
            handler: function (response) {
                setResponseId(response.razorpay_payment_id)
                placeOrderHandler();
                //placeOrderHandler
            },
            prefill: {
                name: "Tagline Traders",
                email: "appsdevpreneur247@gmail.com"
            },
            theme: {
                color: "#F4C430"
            }
        }

        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }

    const createRazorpayOrder = (amount) => {
        console.log('Exec createRazorpayOrder From Client : ', amount)

        let data = JSON.stringify({
            amount: amount * 100,
            currency: "INR"
        })
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "/api/pg-razorpay/order",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data))
                handleRazorpayScreen(response.data.amount)
            })
            .catch((error) => {
                console.log("error at", error)
            })
    }

    const handleClose = () => {
        setOpen(false);
        if (vehicle_shop_invoice_create_success) {
            dispatch({ type: VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_RESET });
            navigate("/admin/vehicle-sales-invoice/list");
        }
    };

    const vehicleStockOutUpdateByVehicleNo = useSelector((state) => state.vehicleStockOutUpdateByVehicleNo);
    const { vehicle_stock_out_update_success, vehicle_stock_out_update_error } = vehicleStockOutUpdateByVehicleNo;

    const vehicleSalesInvoiceCreatedByVehicleNo = useSelector((state) => state.vehicleSalesInvoiceCreatedByVehicleNo);
    const { vehicle_shop_invoice_create_success, vehicle_shop_invoice_create_error } = vehicleSalesInvoiceCreatedByVehicleNo;

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [openSnackBar, setOpenSnackBar] = React.useState(true);

    const placeOrderHandler = () => {
        console.log(" *******  Clicked  placeOrderHandler Vehicle Sale Invoice *******  ");
        console.log("Products in AG Grid :==> ", productsInCart);
        // console.log("invoiceDetails :==> ", invoiceDetails);
        // console.log("itemsPriceObject :==> ", itemsPriceObject);
        // console.log("balanceAmountTobePaid :==> ", balanceAmountTobePaid);

        if (paymentDetails) {
            paymentMadeDetails = paymentDetails
        } else {
            paymentMadeDetails = {
                cashAmountPaid: cashAmount ? cashAmount : 0.0,
                upiAmountPaid: upiAmount ? upiAmount : 0.0,
                paidTo: (paidTo) ? paidTo : "",
                discountApplied: itemsDiscountPrice ? itemsDiscountPrice : 0.0,
                amountPaid: amountPaid ? amountPaid : 0.0,
                balanceAmount: balanceAmountTobePaid ? balanceAmountTobePaid : 0.0,
                orderTotal: itemsPriceObject.totalCartItemsPrice ? itemsPriceObject.totalCartItemsPrice  : 0.0,
            };
        }

        console.log("paymentMadeDetails :==> ", paymentMadeDetails);

        if (productsInCart && invoiceDetails && vehicleNo) {
            const calculatedItemsPriceResult = calculateItemsPriceObject(productsInCart);
            console.log("calculatedItemsPriceResult : ", calculatedItemsPriceResult);

            if (vehicleNo && customerDetails && productsInCart && paymentMadeDetails) {
                console.log('******* BEFORE HITTING THE BE API LOGGING TOTAL CART PRICE AND TAX PRICE *******')
                dispatch(createVehicleSalesInvoice({
                    customerDetails,
                    invoiceDetails,
                    paymentMadeDetails,
                    productsInCart,
                    vehicleNo,
                    itemsPrice: itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? itemsPriceObject.totalCartItemsPrice : 0.0,
                    taxPrice: itemsPriceObject.totalTaxPrice ? itemsPriceObject.totalTaxPrice : 0.0,
                }))
                dispatch(
                    updateStockWhenOutFromVehicleNoAndProductName(
                        invoiceDetails,
                        productsInCart,
                        vehicleNo
                    )
                );
            }
        }
        else
            console.log("UNABLE TO PROCEED AS productsInCart && invoiceDetails && vehicleNoare NULL .!");
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackBar(false);
    };

    const calculateItemsPriceObject = (items) => {
        let itmsPriObj;
        let itemsPrice = items.reduce(
                (acc, eachItem) => acc + eachItem.unitPrice * eachItem.quantity,
                0
            )
            let itemsCount = items.reduce(
                (acc, eachItem) => acc + eachItem.quantity,
                0
            )

        let cgstTaxPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.cgstPrice && eachItem.cgstPrice !== 0 && eachItem.cgstPrice !== null && eachItem.cgstPrice !== undefined && eachItem.cgstPrice !== "" ? eachItem.cgstPrice : 0.0),
                0
            )
        );
        let sgstTaxPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.sgstPrice && eachItem.sgstPrice !== 0 && eachItem.sgstPrice !== null && eachItem.sgstPrice !== undefined && eachItem.sgstPrice !== "" ? eachItem.sgstPrice : 0.0),
                0
            )
        );
        let igstTaxPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.igstPrice && eachItem.igstPrice !== 0 && eachItem.igstPrice !== null && eachItem.igstPrice !== undefined && eachItem.igstPrice !== "" ? eachItem.igstPrice : 0.0),
                0
            )
        );
        let tcsTaxPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.tcsPrice && eachItem.tcsPrice !== 0 && eachItem.tcsPrice !== null && eachItem.tcsPrice !== undefined && eachItem.tcsPrice !== "" ? eachItem.tcsPrice : 0.0),
                0
            )
        );
        itmsPriObj = {
            totalTaxPrice:
                (cgstTaxPrice ? Number(cgstTaxPrice) : 0.0) +
                (sgstTaxPrice ? Number(sgstTaxPrice) : 0.0),
            totalDiscountPrice: 0.0,
            totalCgstTaxPrice: cgstTaxPrice ? Number(cgstTaxPrice) : 0.0,
            totalSgstTaxPrice: sgstTaxPrice ? Number(sgstTaxPrice) : 0.0,
            totalIgstTaxPrice: igstTaxPrice ? Number(igstTaxPrice) : 0.0,
            totalTcsTaxPrice: tcsTaxPrice ? Number(tcsTaxPrice) : 0.0,
            totalCartItemsPrice: itemsPrice ? Number(itemsPrice) : 0.0,
            totalCartItems : itemsCount ? Number(itemsCount) : 0.0
        };
        console.log('CALCULATED itmsPriObj : ', itmsPriObj, ' NOW SETTING TO STATE')
        setItemsPriceObject(() => itmsPriObj);
    };

    const showSuccessDialog = () => {
        console.log("Exe showSuccessDialog Displaying Success Image");
        setOpen(() => true);
    }

    useEffect(() => {
        console.log('useEffect getting Called as productsInCart are getting Changed')
        if (productsInCart && productsInCart.length > 0) {
            calculateItemsPriceObject(productsInCart);
        }
    }, [productsInCart]);

    useEffect(() => {
        console.log("useEffect Getting Called on VehicleSalesPayment Screen as Success on Create New Invoice", vehicle_shop_invoice_create_success);
        if (vehicle_shop_invoice_create_success) {
            console.log(
                "vehicle_shop_invoice_create_success :==> ",
                vehicle_shop_invoice_create_success, " Dispatching to RESET"
            );
            setOpen(() => true);
        }
    }, [vehicle_shop_invoice_create_success]);

    const handlePaymentModeChange = (e) => {
        console.log("Exec handlePaymentModeChange ", e);
        console.log(
            "e.target.name :==> ",
            e.target.name,
            " , e.target.value :==> ",
            e.target.value
        );
        clearFormValueForPaymentModeChange();
        clearFormValueForSinglePaymentType();
        clearFormValueForMultiplePaymentType();
        setPaymentMode((prev) => e.target.value)
    };

    const handleChangeCheckbox = (event) => {
        console.log("event : ", event.target.name, event.target.checked);
        switch (event.target.name) {
            case "isCash":
                if (event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.checked ***** ", event.target.name, event.target.checked)
                    setIsCash(() => true)
                    setPaymentType(() => [...paymentType, "cash"])

                } else if (!event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.UN checked ***** ", event.target.name, event.target.checked)
                    setIsCash(() => false)
                    setAmountPaid((prev) => prev - (upiAmount));
                    console.log(" ***** Deducting cashAmount from amountPaid ***** amountPaid : ", amountPaid)
                    setCashAmount(() => 0.0)
                    setPaidTo(() => "")
                    setPaymentType(() => [...paymentType.filter((i) => i === "cash")],
                    );
                }
                break;
            case "isUpi":
                if (event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.checked ***** ", event.target.name, event.target.checked)
                    setIsUpi(() => true)
                    setPaymentType(() => [...paymentType, "upi"],
                    );
                } else if (!event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target. UN checked ***** ", event.target.name, event.target.checked)
                    setIsUpi(() => false)
                    setAmountPaid((prev) => prev - (cashAmount));
                    console.log(" ***** Deducting upiAmount from amountPaid ***** amountPaid : ", amountPaid)
                    setUpiAmount(() => 0.0)
                    setPaymentType(() => [...paymentType.filter((i) => i !== "upi")],
                    );
                }
                break;
            default:
                break;
        }
    };

    const handleChangeRadioOptions = (event) => {
        event.persist();
        clearFormValueForSinglePaymentType();
        console.log(
            "handleChange Radio event : ",
            event.target.name,
            event.target.value
        );
        if (event.target.value === "cash") {
            setIsCashRadioItem(() => true)
            setIsUpiRadioItem(() => false)
            setPaymentType(["cash"])
            console.log("Have Set Cash Radio Item..");
        }
        else if (event.target.value === "upi") {
            setIsCashRadioItem(() => false)
            setIsUpiRadioItem(() => true)
            setPaymentType(["upi"])
            console.log("Have Set upi  Radio Item..");
        }
    };

    const handleChangePaidTo = (event) => {
        event.persist();
        const paidToValue = event.target.value;
        setPaidTo(() => paidToValue);
    };

    const clearFormValueForPaymentModeChange = () => {
        console.log("clearFormValueForPaymentModeChange ");
        clearFormValueForSinglePaymentType();
        clearFormValueForMultiplePaymentType();
    };

    const clearFormValueForSinglePaymentType = () => {
        console.log("clearFormValueForSinglePaymentType ");
        setIsCashRadioItem((prev) => false)
        setIsUpiRadioItem((prev) => false)
        setCashAmount(() => 0.0)
        setUpiAmount(() => 0.0)
        setPaidTo(() => "")
        setAmountPaid(() => 0.0)
    };

    const clearFormValueForMultiplePaymentType = () => {
        console.log("clearFormValueForMultiplePaymentType ");
        setIsCash((prev) => false)
        setIsUpi((prev) => false)
        setCashAmount(() => 0.0)
        setUpiAmount(() => 0.0)
        setPaidTo(() => "")
        setAmountPaid(() => 0.0)
    };

    const renderDiscTaxTotalAmountContentUsingCards = (
        <Card style={{ backgroundColor: "#F2F2F2" }}>
            <GridContainer spacing={2} alignItems="center" justify="center">
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Total Tax : {itemsPriceObject && itemsPriceObject.totalTaxPrice ? itemsPriceObject.totalTaxPrice : 0.0} %
                    </Typography>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'right', justifyContent: 'center', alignItems: 'center' }} >
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'right', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Total Quantity :  {itemsPriceObject && itemsPriceObject.totalCartItems ? itemsPriceObject.totalCartItems  : 0.0}</Typography>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Total Amount : <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                            <img
                                alt="curency inr"
                                src={rupeeSvgIcon}
                                style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                                className={classes.imageIcon}
                            />
                        </Icon>
                        {itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? formatToLocaleCurrency(itemsPriceObject.totalCartItemsPrice ) : 0.0}
                    </Typography>
                </GridItem>
            </GridContainer>
        </Card>
    );

    const renderUpiDetailsRazorPayVer =
        (
            <Grid container spacing={2} >
                <GridItem item xs={6} style={{ margin: "1rem", textAlign: "center", marginTop: "2rem", marginLeft: "auto", marginRight: "auto" }}>
                    <Typography style={{ textAlign: "center", alignItems: "center", justifyContent: "center", marginRight: "1rem", fontSize: "1.2rem" }}>
                        Paying <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                            <img
                                alt="curency inr"
                                src={rupeeSvgIcon}
                                style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                                className={classes.imageIcon}
                            />
                        </Icon>
                        {itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? formatToLocaleCurrency(itemsPriceObject.totalCartItemsPrice) : 0.0}
                        {" via UPI"}
                        {responseId && <p>Thank you,  Payment ref # {responseId}</p>}
                    </Typography>
                </GridItem>
            </Grid>
        );

    const validateInputLessThanOrderTotal = (val) => {
        console.log("amountPaid : ", amountPaid, upiAmount, cashAmount);
        const orderItemsTotalPrice = itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? itemsPriceObject.totalCartItemsPrice : 0.0;
        if (val > orderItemsTotalPrice) {
            setErrorObject({ errorMessage: "Amount can't exceed  " + orderItemsTotalPrice });
        }
        else
            setErrorObject(null);
        return;
    }

    renderCashDetails =
        (
            <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                <GridItem item md={6} xs={12} sm={6} style={{ textAlign: "left" }}>
                    <TextField
                        label="Cash Amount"
                        required
                        variant="outlined"
                        size="small"
                        name="cashAmount"
                        fullWidth
                        style={{
                            textAlign: "right",
                        }}
                        onChange={(e) => {
                            const cashValue = e.target.value;
                            console.log("CASH Amount : ", cashValue)
                            validateInputLessThanOrderTotal(cashValue);
                            setCashAmount((prev) => Number(cashValue));
                            setAmountPaid((prev) => {
                                if (isUpi) {
                                    return Number(upiAmount) + (cashValue ? Number(cashValue) : 0.0)
                                } else
                                    return cashValue ? Number(cashValue) : 0.0

                            })
                            calculateBalanceAmountIfVer(0, Number(cashValue));
                        }}
                        value={cashAmount ? Number(cashAmount) : null}
                        type="number"
                    />
                    {errorObject && errorObject.errorMessage && (<Typography
                        variant="h5"
                        style={{ textAlign: "center", fontSize: "1rem", margin: "1rem", color: "red" }}
                    >
                        {errorObject.errorMessage}
                    </Typography>)}
                </GridItem>
                <GridItem item md={6} xs={12} sm={6} style={{ textAlign: "right" }}>
                    <TextField
                        label="Cash Paid To"
                        required
                        variant="outlined"
                        size="small"
                        name="paidTo"
                        fullWidth
                        style={{
                            textAlign: "right",
                        }}
                        onChange={handleChangePaidTo}
                        value={paidTo ? paidTo : ""}
                        type="text"
                    />
                </GridItem>
            </Grid>
        );


    const renderMultiplePaymentModesContent = (
        <React.Fragment>
            <FormControl
                sx={{ marginRight: "3rem" }}
                component="fieldset"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    height: "3vh"
                }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCash}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={isCash}
                            name="isCash"
                        />
                    }
                    label="Cash"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isUpi}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={isUpi}
                            name="isUpi"
                        />
                    }
                    label="UPI"
                />
            </FormControl>
        </React.Fragment>
    );

    const renderSinglePaymentModeContent = (
        <React.Fragment>
            <FormControl
                component="fieldset"
                sx={{ marginRight: "3rem" }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    margin: "1rem"

                    // height: "3vh"
                }}
            >
                <RadioGroup
                    row={true}
                    aria-label="paymentType"
                    name="paymentType"
                    value={paymentType}
                    style={{ marginLeft: "0.5rem", textAlign: "center", alignItems: "center", justifyContent: "center" }}
                    onChange={(e) => handleChangeRadioOptions(e)}
                >
                    <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                    <FormControlLabel value="upi" control={<Radio />} label="Upi" />
                </RadioGroup>
            </FormControl>
        </React.Fragment>
    );

    const calculateBalanceAmountIfVer = (upi, cash) => {
        let balanceAmount = 0.0;
        let totalOfDifferentModes = 0.0;
        console.log("Exec calculateBalanceAmountIfVer : UPI Amount : ", upi, " , cashAmount : ", cash);
        console.log(' ****** paymentMode ******  : ', paymentMode)
        if (paymentMode === "Single") {
            if (isUpiRadioItem) {
                totalOfDifferentModes = totalOfDifferentModes + (upi ? Number(upi) : 0.0);
                console.log("totalOfDifferentModes When isUpi is Selected :=> ", totalOfDifferentModes);
            } else if (isCashRadioItem) {
                totalOfDifferentModes = totalOfDifferentModes + (cash ? Number(cash) : 0.0);
                console.log("totalOfDifferentModes  When isCash is Selected:=> ", totalOfDifferentModes);
            }
        }

        if (paymentMode === "Multiple") {
            if (paymentType.includes("cash")) {
                console.log("totalOfDifferentModes When Multiple  && isCash is Selected :=> ", totalOfDifferentModes);
                totalOfDifferentModes =
                    totalOfDifferentModes +
                    (cash
                        ? Number(cash)
                        : 0.0)
            }
            else if (paymentType.includes("upi")) {
                console.log("totalOfDifferentModes When Multiple  && isUpi is Selected :=> ", totalOfDifferentModes);
                totalOfDifferentModes =
                    totalOfDifferentModes +
                    (upi
                        ? Number(upi)
                        : 0.0)
            }
            console.log("totalOfDifferentModes When Multiple  && isUpi is Selected :=> ", totalOfDifferentModes);
        }

        console.log("itemsPriceObject :=> ", itemsPriceObject);
        console.log("totalOfDifferentModes : ", totalOfDifferentModes, " UPI : ", upi, " CASH : ", cash, "itemsPriceObject.totalCartItemsPrice", itemsPriceObject.totalCartItemsPrice);
        balanceAmount = Number(itemsPriceObject.totalCartItemsPrice) - Number(totalOfDifferentModes)
        setBalanceAmountTobePaid((prev) => balanceAmount);
    };

    return (
        <React.Fragment>
            {customError && customError.errorMessage && customError.errorMessage !== "" && (<Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar} key={vertical + horizontal}>
                <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                    Please Ensure Cash Amount doesn't exceed 2 Laks.!
                </Alert>
            </Snackbar>)}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <GridContainer spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12} >
                            <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                                    <img
                                        alt="Order Placed"
                                        src={greeTickImage}
                                        style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                                    />
                                </div>
                                <Typography
                                    variant="h5"
                                    color="black"
                                    style={{
                                        margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                                        fontWeight: "600",
                                        fontSize: "1rem",
                                        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                    }}
                                >
                                    Thanks , your order is placed.
                                </Typography>
                            </Paper>
                        </Grid>
                    </GridContainer>
                </DialogContent>
            </BootstrapDialog>
            <GridContainer spacing={2} alignItems="center" justify="center">
                <Grid item xs={12} sm={12} md={12}>
                    <Paper style={{ margin: "1rem" }}>
                        {renderDiscTaxTotalAmountContentUsingCards}
                    </Paper>
                </Grid>
            </GridContainer>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card >
                        <CardBody>
                            <GridContainer spacing={2}>
                                <GridItem item xs={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center", width: '100%' }}>
                                    <InputLabel id="demo-simple-select-label">
                                        Payment Mode
                                    </InputLabel>
                                    <Select
                                        label="Payment Mode"
                                        placeholder="Payment Mode"
                                        variant="outlined"
                                        required
                                        size="small"
                                        name="paymentMode"
                                        onChange={handlePaymentModeChange}
                                        value={paymentMode}
                                        style={{ width: '50%' }}
                                    >
                                        <MenuItem key="Single" value="Single">Single</MenuItem>
                                        <MenuItem key="Multiple" value="Multiple">Multiple</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem item xs={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                                    {paymentMode === "Single" &&
                                        renderSinglePaymentModeContent}
                                    {paymentMode === "Multiple" &&
                                        renderMultiplePaymentModesContent}
                                </GridItem>
                                {isCash || isCashRadioItem ? (
                                    <GridItem xs={12} sm={12} md={12} >
                                        {renderCashDetails}
                                    </GridItem>
                                ) : null}
                                {isUpi || isUpiRadioItem ? (
                                    <GridItem xs={12} sm={12} md={12} >
                                        {renderUpiDetailsRazorPayVer}
                                    </GridItem>
                                ) : null}
                                {isCash || isCashRadioItem === true && (<GridItem xs={12} sm={12} md={12} style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        color="secondary"
                                        onClick={placeOrderHandler}
                                        style={{
                                            textTransform: "capitalize",
                                            borderRadius: "5px",
                                            width: "25%",
                                        }}
                                        disabled={((isUpi === true || isUpiRadioItem === true) && upiReference && upiReference.length > 0) ?
                                            false : ((isCash === true || isCashRadioItem === true) && paidTo && paidTo.length > 0) ?
                                                false : (errorObject && errorObject.errorMessage && errorObject.errorMessage.length === 0 ? false : true)}
                                    >
                                        Place Order
                                    </Button>
                                </GridItem>)}

                                {(isUpi || isUpiRadioItem === true) && (
                                    <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                                        <Grid
                                            item={true}
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            style={{ marginBottom: "0.5rem", textAlign: "center" }}
                                        >
                                            <Button
                                                size="small"
                                                variant="contained"
                                                type="submit"
                                                color="secondary"
                                                fullWidth={true}
                                                style={{
                                                    textTransform: "capitalize",
                                                    width: "18vh",
                                                    borderRadius: "5px",
                                                    position: "relative",
                                                    bottom: "0"
                                                }}
                                                disabled={(isUpiRadioItem === true) ? false : true}
                                                onClick={() => createRazorpayOrder(itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? itemsPriceObject.totalCartItemsPrice : 0.0)}
                                            >
                                                Continue UPI Payment
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </GridContainer>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default VehicleSalesPayment;
