import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import { CurrencyRupee, DeleteForeverRounded } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';
import {
  Divider,
  Autocomplete,
  Box,
  Icon,
  IconButton,
} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import { listVehicleSalesInvoices, salesInvoiceByVehicleNo } from "../../../../actions/vehicleSalesInvoiceAction.js";

import { createInvoice } from "../../../../actions/vehicleSalesInvoiceAction";
import { updateStockWhenOutFromVehicleNoAndProductName } from "../../../../actions/vehicleStockAction";
import { listStockByVehicleNo } from "../../../../actions/vehicleStockAction";
import { listBulkByProductId } from "../../../../actions/bulkAction";
import { listDomesticByProductId } from "../../../../actions/domesticAction";
import { fetchVehicleByUserName, listAllVehicles } from "../../../../actions/vehicleAction";
import { listAllProducts } from "../../../../actions/productAction";
import { formatToLocaleCurrency } from '../../../../util/Utility.js'
import {
  Typography,
  Grid,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";

import CustomBackdropSpinner from "../../../CustomBackdropSpinner";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import VehicleSalesPayment from "./VehicleSalesPayment";
import { listCustomersByParentUser } from "../../../../actions/customerAction";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import rupeeSvgIcon from "../../../../assets/images/currency-inr.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const VehicleSalesInvoiceCreateScreen = ({ match }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('USE EFFECT TO FETCH ALL PRODUCTS IS XALLED ONLY ONCE..!')
    dispatch(listAllProducts())
  }, []);

  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, role, userName } = userInfo;
  const [errorObject, setErrorObject] = useState(() => null);
  let renderContentUsingCards = "";
  let renderVehicleDetailsUsingCards = ""

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [customProducts, setCustomProducts] = useState(() => []);
  let customVehicles = [];
  let tempCustomers = [];
  let vehicleIncharges = [];
  let drivers = [];

  const [productsTobeAddedInInvoice, setProductsTobeAddedInInvoice] = useState(
    () => []
  );
  const [maxQuantityAllowed, setMaxQuantityAllowed] = React.useState(() => 0);
  const [maxQuantityAllowedErrorMessage, setMaxQuantityAllowedErrorMessage] = React.useState("");

  const [selectedProduct, setSelectedProduct] = useState("");

  const [vehicleDetails, setVehicleDetails] = useState({
    vehicleNo: "",
    salesIncharge: "",
    driverName: "",

  });
  const [calculatedUnitPrice, setCalculatedUnitPrice] = useState(() => {
    return 0.0;
  });
  const [productFormInputs, setProductFormInputs] = useState({
    name: "",
    orderTypeSelected: "Domestic",
    uom: "",
    quantity: "",
    unitPrice: "",
    cgst: "",
    sgst: "",
    igst: "",
    tcs: "",
    cgstPrice: "",
    sgstPrice: "",
    igstPrice: "",
    tcsPrice: "",
    inKgs: 0,
    subCategory: "",
    discountPercent: "",
    preferreDiscountPercent: "",
    discountStartsFrom: "",
    itemsDiscountPrice: "",
    itemCost: "",
    productTax: "",
    productTaxAmount: "",
    isValidToAddToCart: false
  });

  const [invoiceFormInputs, setInvoiceFormInputs] = useState({
    total: 0.0,
    vehicleNo: "",
    driverName: "",
    stockTransferInvoiceStatus: "",
    salesIncharge: "",
    billDate: new Date(),
    billNo: "",
    isCashSale: false,
    shift: "",
  });

  const [autoCompleteCustomer, setAutoCompleteCustomer] =
    useState(() => "");

  const prepareCustomerDetailsObject = (custObj) => {
    console.log('START prepareCustomerDetailsObject , invoiceFormInputs.isCashSale : ', invoiceFormInputs.isCashSale, ' custObj : ', custObj)
    if (invoiceFormInputs.isCashSale && !custObj) {
      setCustomerDetailsObject((prevState) => ({
        ...prevState,
        name: "CASH SALE",
        contactNo: "",
        address: ""
      }));
    } else if ((!invoiceFormInputs.isCashSale) && custObj) {
      console.log('GAME STARTS NOW SETTING NAME custObj.name :', custObj.name, ' custObj.contactNo : ', custObj.contactNo, 'custObj.address : ', custObj.address)
      setCustomerDetailsObject((prevState) => ({
        ...prevState,
        name: custObj && custObj.name ? custObj.name : "",
        contactNo: custObj && custObj.contactNo ? custObj.contactNo : "",
        address: custObj && custObj.address ? custObj.address : "",
      }));
    } else
      console.log('prepareCustomerDetailsObject FAILED AS autoCompleteCustomer : is null', autoCompleteCustomer)

    console.log('DONE prepareCustomerDetailsObject ')
  }
  const populateVehicleDetails = () => {
    console.log('Exec populateVehicleDetails !')
    if (fetch_vehicle_by_user_data) {
      const { vehicleNo, assignedTo, driverName } = fetch_vehicle_by_user_data;
      console.log('vehicleNo , assignedTo, driverName ', vehicleNo, assignedTo, driverName)
      setVehicleDetails({ vehicleNo: vehicleNo, salesIncharge: assignedTo, driverName: driverName })
    }
    console.log('DONE Setting vehicleNo , assignedTo, driverName to State End:')
  }

  const [selectedCustomer, setSelectedCustomer] = useState(() => "");
  const [productId, setProductId] = useState(() => "");
  let [orderTypeSelected, setOrderTypeSelected] = useState(() => "Domestic");

  const allProducts = useSelector((state) => state.allProducts);
  const { all_products_loading, all_products } = allProducts;

  const bulkListByProductId = useSelector((state) => state.bulkListByProductId);
  const domesticListByProductId = useSelector(
    (state) => state.domesticListByProductId
  );

  const { bulk } = bulkListByProductId;
  const { domestic } = domesticListByProductId;

  const vehicleSalesIncharge = useSelector((state) => state.vehicleSalesIncharge);
  const { fetch_vehicle_by_user_loading, fetch_vehicle_by_user_data, fetch_vehicle_by_user_error } = vehicleSalesIncharge;
  // console.log('fetch_vehicle_by_user_data : ', fetch_vehicle_by_user_data)

  const customerListByParentUser = useSelector((state) => state.customerListByParentUser);
  const { customer_list_parent_user, customer_list_parent_user_error, customer_list_parent_user_success } = customerListByParentUser;

  const vehicleStockListByVehicleNo = useSelector(
    (state) => state.vehicleStockListByVehicleNo
  );
  const [customerDetailsObject, setCustomerDetailsObject] = useState(() => { });
  const vehicleStockOutUpdateByVehicleNo = useSelector((state) => state.vehicleStockOutUpdateByVehicleNo);
  const { vehicle_stock_out_update_success, vehicle_stock_out_update_error } = vehicleStockOutUpdateByVehicleNo;
  const vehicleSalesInvoiceCreatedByVehicleNo = useSelector((state) => state.vehicleSalesInvoiceCreatedByVehicleNo);
  const { vehicle_shop_invoice_create_success, vehicle_shop_invoice_create_error } = vehicleSalesInvoiceCreatedByVehicleNo;
  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const vehicleSalesInvoiceDetailsByVehicleNo = useSelector((state) => state.vehicleSalesInvoiceDetailsByVehicleNo);
  const {
    vehicle_sale_invoice_by_vehicle_no_data,
  } = vehicleSalesInvoiceDetailsByVehicleNo;
  const { vehicle_stock_by_vehicle_no_data, vehicle_stock_by_vehicle_no_loading, vehicle_stock_by_vehicle_no_error } = vehicleStockListByVehicleNo;
  const [isDuplicateInvoice, setIsDuplicateInvoice] = React.useState(() => false);

  if (users && users.length > 0) {
    vehicleIncharges = users.filter(each => each.role === "VEHICLE_SALES_INCHARGE_ROLE");
    drivers = users.filter(each => each.role === "DRIVER_ROLE");
  }

  const checkIfValidQuantity = (event) => {
    let isValid = false;
    if (Number(event.target.value) > Number(maxQuantityAllowed)) {
      setMaxQuantityAllowedErrorMessage("Max Allowed Quantity is : " + maxQuantityAllowed)
      isValid = false
    } else isValid = true
    return isValid
  }

  const showMaximumQuantityAllowed = (prd) => {
    console.log("Getting the Max Quantity Allowed for Product : ", prd);
    let maxQuantityAllowed = 0;
    let foundProduct = []
    if (prd && prd !== null) {
      if (customProducts && customProducts.length > 0) {
        foundProduct = customProducts.filter(e => e.productName === prd.productName);
        if (foundProduct && foundProduct.length > 0) {
          console.log("foundProduct  : ", foundProduct[0]);
          maxQuantityAllowed = foundProduct[0].countInStock;
          console.log('Displaying maxQuantityAllowed : ', maxQuantityAllowed, ' for Product ', prd.productName)
        } else
          console.log("Unable to find Product so couldnt get Max Quantity Allowed for  : ", prd.productName);
      } else {
        console.log("Failed Getting the Max Quantity Allowed from Vehicle for Product : ", prd);
      }
    }
    setMaxQuantityAllowed(() => maxQuantityAllowed);
  };

  useEffect(() => {
    console.log("useEffect Getting Called  Vehicle Sales List Screen");
    if (vehicle_stock_out_update_success) {
      console.log(
        "vehicle_stock_out_update_success :==> ",
        vehicle_stock_out_update_success,
      );
      navigate("/admin/vehicle-sales-invoice/list");
    }
  }, [
    dispatch,
    navigate,
    vehicle_stock_out_update_success
  ]);

  useEffect(() => {
    if (vehicle_stock_by_vehicle_no_data)
      loadOnlyProductsWithValidStock()
  }, [vehicle_stock_by_vehicle_no_data]);

  useEffect(() => {
    if (userName && userName.length > 0)
      dispatch(fetchVehicleByUserName(userName))
  }, [dispatch, userName]);

  useEffect(() => {
    if (fetch_vehicle_by_user_data)
      populateVehicleDetails()
  }, [fetch_vehicle_by_user_data]);

  useEffect(() => {
    if (vehicleDetails && vehicleDetails.vehicleNo && vehicleDetails.vehicleNo.length > 0)
      dispatch(salesInvoiceByVehicleNo(vehicleDetails.vehicleNo));
  }, [dispatch, vehicleDetails.vehicleNo]);

  const loadOnlyProductsWithValidStock = () => {
    if (vehicle_stock_by_vehicle_no_data && vehicle_stock_by_vehicle_no_data.length > 0) {
      const productsInStock = vehicle_stock_by_vehicle_no_data.filter((prd => prd.countInStock > 0))
      console.log('vehicle_stock_by_vehicle_no After Filter of Out Of Stock : ', productsInStock.length);
      if (productsInStock && productsInStock.length > 0)
        setCustomProducts((prev) => productsInStock)
    }
  }

  if (customer_list_parent_user && customer_list_parent_user.length > 0) tempCustomers = customer_list_parent_user;

  useEffect(() => {
    if (name && name.length > 0 && role && role === "VEHICLE_SALES_INCHARGE_ROLE") {
      console.log('Exec listCustomersByParentUser for name, userName', name, userName)
      dispatch(listCustomersByParentUser(name));
    }
  }, [dispatch, role, name]);

  useEffect(() => {
    if (vehicleDetails && vehicleDetails.vehicleNo && vehicleDetails.vehicleNo.length > 0)
      setInvoiceFormInputs((prevState) => ({
        ...prevState,
        vehicleNo: vehicleDetails.vehicleNo,
        salesIncharge: vehicleDetails.salesIncharge && vehicleDetails.salesIncharge.length > 0 ? vehicleDetails.salesIncharge : "",
        driverName: vehicleDetails.driverName && vehicleDetails.driverName.length > 0 ? vehicleDetails.driverName : "",
      }));

    dispatch(listStockByVehicleNo(vehicleDetails.vehicleNo));
  }, [dispatch, vehicleDetails.vehicleNo]);

  useEffect(() => {
    if (selectedProduct) {
      console.log("useEffect been called for selectedProduct : ", selectedProduct)
      const product = getProductIdByProductName(selectedProduct);

      console.log("product after call to getProductIdByProductName: ", product);
      if (product && product.length > 0 && product[0] && product[0]._id) {
        console.log(
          "Found the Product Id so Invoking the Bulk or Domestic for Product Name : ",
          selectedProduct,
          product[0]._id
        );
        dispatch(listDomesticByProductId(product[0]._id));
      } else {
        console.log(
          "Didnt find the Product Id so Not Invoking the Bulk or Domestic for Product Name : ",
          selectedProduct
        );
      }
    }
  }, [dispatch, selectedProduct, orderTypeSelected]);

  useEffect(() => {
    console.log("Change in productFormInputs : ", productFormInputs);
    /** CALCULATION OF TAX AND DISCOUNT LOGIC */
    calculateItemCostPriceIncludingTax(
      productFormInputs.unitPrice,
      productFormInputs.inKgs,
      productFormInputs.quantity,
      productFormInputs.cgst,
      productFormInputs.sgst,
      productFormInputs.igst,
      productFormInputs.tcs,
      productFormInputs.discountPercent,
      productFormInputs.discountStartsFrom,
      productFormInputs.customerType
    );
  }, [productFormInputs.quantity, productFormInputs.discountPercent]);

  useEffect(() => {
    if (domestic) {
      console.log("useEffect Getting Called as Change in Domestic  ");
      renderUnitOfMessure()
    }
  }, [domestic]);

  useEffect(() => {
    if (invoiceFormInputs.billDate && invoiceFormInputs.billNo) {
      console.log('Exec listCustomersByParentUser for name, userName', invoiceFormInputs.billDate, invoiceFormInputs.billNo)
      checkIfDuplicateInvoice()
    }
  }, [invoiceFormInputs.billDate]);

  const renderUnitOfMessure = () => {
    if (domestic && domestic[0]) {
      console.log('domestic ::==>  ', domestic);
      const uomValue = domestic[0].unitOfMessure
      setProductFormInputs((prevState) => ({
        ...prevState,
        uom: uomValue,
        mrp: domestic[0].mrp ? formatToLocaleCurrency(domestic[0].mrp) : 0.0,
        discountPercent: domestic[0].discountPercent ? domestic[0].discountPercent : 0.0,
        discountPercent: domestic[0].discountPercent ? domestic[0].discountPercent : 0.0,
        discountStartsFrom: domestic[0].discountStartsFrom ? domestic[0].discountStartsFrom : '',
        unitPrice: domestic[0].unitPrice ? formatToLocaleCurrency(domestic[0].unitPrice) : 0.0,
        cgst: domestic[0].cgst ? domestic[0].cgst : 0.0,
        sgst: domestic[0].sgst ? domestic[0].sgst : 0.0,
        igst: domestic[0].igst ? domestic[0].igst : 0.0,
        tcs: domestic[0].tcs ? domestic[0].tcs : 0.0,
        inKgs: domestic[0].inKgs ? domestic[0].inKgs : 0.0,
      }));
    }
  };

  const handleQuantityChange = (event) => {
    console.log("handleQuantityChange : ", event, "selectedProduct : ", selectedProduct)
    // CHECK IF VALID QUANTITY IS ENTERED & RESTRICT HIM ENETERING MORE THAN WHAT WE HAVE AT STORE
    if (selectedProduct && selectedProduct._id !== null && event.target.value) {
      // Check Max Quantity Allowed Number(event.target.value) >
      if (checkIfValidQuantity(event)) {
        console.log('Is Valid Quantity !')
        setMaxQuantityAllowedErrorMessage(() => "")
        setProductFormInputs((prevState) => ({
          ...prevState,
          quantity: event.target.value ? Number(event.target.value) : 0,
          isValidToAddToCart: true
        }));
      } else {
        console.log(" handleQuantityChange Check is Invalid Quantity.! ")
        setMaxQuantityAllowedErrorMessage("Max Allowed Quantity is : " + maxQuantityAllowed)
        setProductFormInputs((prevState) => ({
          ...prevState,
          isValidToAddToCart: false
        }));
      }
    }
  }


  const filterAddedCartItemFromProductsList = () => {
    const filterd = vehicle_stock_by_vehicle_no_data.filter(cpEach => productsTobeAddedInInvoice.some(eachCartItem => eachCartItem.name === cpEach.name))
    console.log('******* filterd ', filterd, ' ******* ')
    setCustomProducts(() => filterd);
  }

  const handleQuantityChangeInCart = (event, currentCartItem) => {
    console.log("handleQuantityChangeInCart : ", event, "selectedProduct : ", selectedProduct)
    // CHECK IF VALID QUANTITY IS ENTERED & RESTRICT HIM ENETERING MORE THAN WHAT WE HAVE AT STORE
    if (selectedProduct && selectedProduct._id !== null && event.target.value) {
      if (checkIfValidQuantity(event)) {
        console.log('Is Valid Quantity !')
        setMaxQuantityAllowedErrorMessage(() => "")
        const fountItem = productsTobeAddedInInvoice.find(
          (x) => x.name === currentCartItem.name
        );
        if (fountItem) {
          console.log("Items Exists in CART SO MATCHED : ", fountItem);
          setProductsTobeAddedInInvoice((prev) => {
            return productsTobeAddedInInvoice.map((x) => x.name === fountItem.name ? { ...x, quantity: event.target.value ? Number(event.target.value) : 0 } : x)
          })
        }
      } else {
        console.log(" handleQuantityChange Check is Invalid Quantity.! ")
        setMaxQuantityAllowedErrorMessage("Max Allowed Quantity is : " + maxQuantityAllowed)
        setProductFormInputs((prevState) => ({
          ...prevState,
          isValidToAddToCart: false
        }));
      }
    }
  }

  const handleInvoiceChange = (event) => {
    setInvoiceFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleIsCashFlowChange = (event) => {
    console.log('Exec handleIsCashFlowChange ...', event.target.checked, 'autoCompleteCustomer : ', autoCompleteCustomer)
    if (event.target.checked) {
      prepareCustomerDetailsObject(null)
      setInvoiceFormInputs((prevState) => ({
        ...prevState,
        isCashSale: event.target.checked,
      }));
      setAutoCompleteCustomer(() => "");
      setSelectedCustomer(() => "");
    } else {
      setInvoiceFormInputs((prevState) => ({
        ...prevState,
        isCashSale: event.target.checked,
      }));
    }

  };

  const handleClearProductForm = () => {
    setProductFormInputs(() => {
      return {
        name: "",
        quantity: "",
        unitPrice: "",
        discountPercent: "",
        discountStartsFrom: "",
        cgst: "",
        sgst: "",
        igst: "",
        tcs: "",
        inKgs: "",
        taxAmount: "",
        subCategory: "",
        itemCost: "",
        productTax: "",
        productTaxAmount: "",
        orderTypeSelected: "Domestic",
        isValidToAddToCart: false
      };
    });
    setOrderTypeSelected(() => "Domestic");
  };

  const handleAddProduct = () => {
    console.log(
      "selectedProduct : ",
      selectedProduct
    );
    // IF PRODUCT ALREADY EXISTS , THE ADD THE CURRENT QTY || OR DONT SHOW THE PRODUCT AS IT IS ALREADY IN THE CART
    setProductsTobeAddedInInvoice([
      ...productsTobeAddedInInvoice,
      {
        name: selectedProduct.productName,
        uom: productFormInputs.uom ? productFormInputs.uom : '',
        quantity: productFormInputs.quantity ? productFormInputs.quantity : 0,
        unitPrice: productFormInputs.unitPrice ? productFormInputs.unitPrice : 0.0,
        discountPercent: productFormInputs.discountPercent ? productFormInputs.discountPercent : 0.0,
        discountStartsFrom: productFormInputs.discountStartsFrom,
        cgst: productFormInputs.cgst,
        cgstPrice: productFormInputs.cgstPrice,
        sgst: productFormInputs.sgst,
        sgstPrice: productFormInputs.sgstPrice,
        igst: productFormInputs.igst,
        igstPrice: productFormInputs.igstPrice,
        tcs: productFormInputs.tcs,
        tcsPrice: productFormInputs.tcsPrice,
        itemsDiscountPrice: productFormInputs.itemsDiscountPrice,
        subCategory: "",
        itemCost: Number(productFormInputs.quantity) * Number(productFormInputs.unitPrice),
        inkgs: productFormInputs.inKgs,
        productTax: Number(productFormInputs.sgst) + Number(productFormInputs.cgst),
        productTaxAmount: formatToLocaleCurrency((((Number(productFormInputs.unitPrice) * Number(productFormInputs.quantity)) * 100) / (100 + (Number(productFormInputs.cgst) + Number(productFormInputs.sgst)))) * ((Number(productFormInputs.cgst) + Number(productFormInputs.sgst)) / 100)),
      },
    ]);

    handleClearProductForm();
  };

  const getProductIdByProductName = (prdObject) => {
    console.log("getProductIdByProductName : ", prdObject, all_products);
    const result = all_products.filter(
      (product) => product.name === prdObject.productName
    );
    console.log(
      "Filter RESULT ::==>> ",
      result ? result.length : " Filter couldnt foind any..! ",
      result
    );
    return result;
  };

  const decrementQuantity = (item) => {
    console.log("decrementQuantity ..! Payload : ", item);
    const fountItem = productsTobeAddedInInvoice.find((x) => x.name === item.name);
    if (fountItem) {
      console.log("Items Exists in CART SO MATCHED : ", fountItem);
      setProductsTobeAddedInInvoice((prev) => {
        return productsTobeAddedInInvoice.map((x) => x.name === fountItem.name ? { ...x, quantity: Number(x.quantity) > 0 ? Number(x.quantity) - 1 : 1 } : x)
      })
    }
  }

  const incrementQuantity = (item) => {
    const fountItem = productsTobeAddedInInvoice.find(
      (x) => x.name === item.name
    );
    if (fountItem) {
      console.log("Items Exists in CART SO MATCHED : ", fountItem);
      setProductsTobeAddedInInvoice((prev) => {
        return productsTobeAddedInInvoice.map((x) => x.name === fountItem.name ? { ...x, quantity: Number(x.quantity) > 0 ? Number(x.quantity) + 1 : 1 } : x)
      })
    }
  }

  const removeFromCartHandler = (name) => {
    productsTobeAddedInInvoice.map((x) => console.log(x))
    const filteredRowData = productsTobeAddedInInvoice.filter((x) => x.name !== name)
    setProductsTobeAddedInInvoice(() => filteredRowData);
  };

  renderVehicleDetailsUsingCards = (
    <GridContainer spacing={2} alignItems="center" justify="center" style={{ marginTop: '1rem', marginBottom: '1rem', padding: '10px' }}>
      <GridItem xs={12} sm={12} md={12} style={{ margin: '0px', padding: '0px' }}>
        <Card style={{ margin: '0px', padding: '0px' }}>
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} >
                <TextField
                  label="Vehicle No"
                  disabled
                  value={vehicleDetails && vehicleDetails.vehicleNo ? vehicleDetails.vehicleNo : ""}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker value={invoiceFormInputs.billDate} label="Bill Date" style={{ width: "25vh", margin: "1rem" }} size="small" onChange={(newValue) => {
                    setBillDate(newValue);
                    setInvoiceFormInputs((prevState) => ({
                      ...prevState,
                      billDate: newValue,
                    }));
                    console.log("newValue", newValue);
                  }} renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                <TextField
                  disabled
                  label="Sales Incharge"
                  value={vehicleDetails && vehicleDetails.salesIncharge ? vehicleDetails.salesIncharge : ""}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                <TextField
                  disabled
                  label="Driver Name"
                  value={vehicleDetails && vehicleDetails.driverName ? vehicleDetails.driverName : ""}
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  renderContentUsingCards = (
    <>
      {productsTobeAddedInInvoice && productsTobeAddedInInvoice.map((eachCartItem, idx) => (
        <GridItem xs={12} sm={12} md={12} key={idx}>
          <Card style={{ backgroundColor: "#F2F2F2" }}>
            <CardBody>
              <GridContainer spacing={2} alignItems="center" justify="center">
                <GridItem xs={9} sm={9} md={9}>
                  <Typography
                    style={{ margin: "0.25rem", fontWeight: '600', fontSize: "1rem", alignItems: "left", justifyContent: "left", textAlign: "left" }}
                    color="black"
                  >
                    {eachCartItem.name}
                  </Typography>
                </GridItem>
                <GridItem xs={3} sm={3} md={3} style={{ textAlign: "right", justifyContent: "center", alignItems: "center" }}>
                  <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                    <img
                      alt="curency inr"
                      src={rupeeSvgIcon}
                      style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                      className={classes.imageIcon}
                    />
                  </Icon>
                  {eachCartItem.unitPrice}
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Typography
                    style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem" }}
                    color="black"
                  >
                    Item Subtotal
                  </Typography>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: "right", justifyContent: "center", alignItems: "center" }}>
                  {eachCartItem.quantity} X {eachCartItem.unitPrice}
                  <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                    <img
                      alt="curency inr"
                      src={rupeeSvgIcon}
                      style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                      className={classes.imageIcon}
                    />
                  </Icon>
                  {eachCartItem.unitPrice}
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Typography
                    style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem" }}
                    color="black"
                  >
                    Tax
                  </Typography>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: "right", justifyContent: "center", alignItems: "center" }}>
                  <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                  </Icon>
                  {eachCartItem.productTax} %
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right", justifyContent: "center", alignItems: "center" }}>
                  <button onClick={() => decrementQuantity(eachCartItem)} style={{
                    margin: "5px",
                    fontSize: "10px",
                    cursor: "pointer",
                    width: "1.5rem",
                    height: "1.5rem",
                    alignItems: "center",
                    justifyContent: "center",
                    justifyItems: "center",
                  }}
                  >-</button>
                  <TextField
                    label="Quantity"
                    variant="outlined"
                    size="small"
                    style={{
                      width: "5rem",
                      height: "1.5rem",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                      marginTop: '0.25rem',
                      background: "white"
                    }}
                    name="quantity"
                    required
                    value={eachCartItem.quantity}
                    onChange={(e) => {
                      console.log("Qty : ", e.target.value);
                      const qty = Number(e.target.value);
                      if (qty) {
                        handleQuantityChangeInCart(e, eachCartItem)
                      }
                    }}
                    type="text"
                  />
                  <button onClick={() => incrementQuantity(eachCartItem)} style={{
                    padding: "5px",
                    fontSize: "12px",
                    cursor: "pointer",
                    width: "1.5rem",
                    height: "1.5rem",
                    alignItems: "center",
                    justifyContent: "center",
                    justifyItems: "center",
                    margin: "5px"
                  }}>+</button>
                  <IconButton
                    aria-label="delete" style={{ textAlign: "center", justifyContent: "center", color: "#FF0800" }}
                    onClick={() =>
                      removeFromCartHandler(eachCartItem.name)
                    }
                  >
                    <DeleteForeverRounded color="#FF0800" />
                  </IconButton>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      ))}
    </>
  );


  const calculateItemCostPriceIncludingTax = (
    unitPrice,
    inKgs,
    quantity,
    cgst,
    sgst,
    igst,
    tcs,
    discountPercent,
    discountStartsFrom,
    customerType
  ) => {
    console.log(
      "unitPrice, quanity, tax, discount : ",
      unitPrice,
      inKgs,
      quantity,
      cgst,
      sgst,
      igst,
      tcs,
      discountPercent,
      discountStartsFrom,
      customerType
    );

    const cgstPrice = unitPrice * quantity * (cgst / 100);
    const sgstPrice = unitPrice * quantity * (sgst / 100);
    const igstPrice = unitPrice * quantity * (igst / 100);
    const tcsPrice = unitPrice * quantity * (tcs / 100);
    let itemsDiscountPrice = 0;
    let totalTaxPrice = 0;
    console.log(" cgst : ", cgst, "cgstPrice : ", cgstPrice, "sgst : ", sgst, "sgstPrice : ", sgstPrice, "igst : ",
      igst, "igstPrice : ", igstPrice, "tcs : ", tcs, "tcsPrice : ", tcsPrice);
    const itemsActualWeight = quantity * inKgs;
    console.log("inKgs : ", inKgs, "items Quantity Ordered : ", quantity * inKgs);
    const eachItemIndividualTotalPrice = unitPrice * quantity;
    let calculatedItemCostPriceIncludingDiscountAndTax = 0;

    if (itemsActualWeight && discountStartsFrom && itemsActualWeight >= discountStartsFrom) {
      console.log(
        "Eligible for Discount Price on this Item inKgs : ",
        inKgs,
        "items Quantity Ordered : ",
        quantity * inKgs, "discountPercent : ", discountPercent
      );
      itemsDiscountPrice = ((unitPrice * quantity) * (discountPercent / 100));
      console.log("itemsDiscountPrice ::==>>  ", itemsDiscountPrice);

      console.log("cgstPrice , sgstPrice , igstPrice , tcsPrice : ", cgstPrice, sgstPrice, igstPrice, tcsPrice);
      totalTaxPrice = (cgstPrice + sgstPrice + igstPrice + tcsPrice);
      console.log("eachItemIndividualTotalPrice : ", eachItemIndividualTotalPrice, " , itemsDiscountPrice : ", itemsDiscountPrice, "totalTaxPrice : ", totalTaxPrice);
      calculatedItemCostPriceIncludingDiscountAndTax = eachItemIndividualTotalPrice - itemsDiscountPrice;
      console.log("calculatedItemCostPriceIncludingDiscountAndTax : ", calculatedItemCostPriceIncludingDiscountAndTax);
    } else {
      console.log(
        "NOT Eligible for Discount Price on this Item , itemsActualWeight: ",
        itemsActualWeight,
        "discountStartsFrom : ",
        discountStartsFrom
      );
      calculatedItemCostPriceIncludingDiscountAndTax =
        eachItemIndividualTotalPrice; // COMMENTING AS UNIT PRICE ALREADY INCLUDES TAX [TICS] + totalTaxPrice;
      console.log("calculatedItemCostPriceIncludingDiscountAndTax : ", calculatedItemCostPriceIncludingDiscountAndTax);
    }

    console.log(
      "calculatedItemCostPriceIncludingDiscountAndTax : ",
      calculatedItemCostPriceIncludingDiscountAndTax
    );
    setProductFormInputs((prevState) => ({
      ...prevState,
      itemCost: calculatedItemCostPriceIncludingDiscountAndTax,
      cgstPrice: cgstPrice,
      sgstPrice: sgstPrice,
      igstPrice: igstPrice,
      tcsPrice: tcsPrice,
      itemsDiscountPrice: itemsDiscountPrice,
    }));
  };

  const checkIfDuplicateInvoice = () => {
    console.log('Exec checkIfDuplicateInvoice of Vehicle Sale Invoice : ', invoiceFormInputs.billDate, invoiceFormInputs.billNo)
    let isDuplicate = false;
    if (vehicle_sale_invoice_by_vehicle_no_data && vehicle_sale_invoice_by_vehicle_no_data.length > 0) {
      isDuplicate = vehicle_sale_invoice_by_vehicle_no_data.some(item => item.billNo === invoiceFormInputs.billNo
      );
      if (isDuplicate) {
        console.log('checkIfDuplicateInvoice of Vehicle Sale Invoice is DUPLICATE INVOICE BY BILL NO : ', isDuplicate)
        setIsDuplicateInvoice(() => isDuplicate)
        setErrorObject({ errorMessage: "Bill No is Duplicate  " });
      } else
        setErrorObject(null);
    }
  }

  return (
    <div className={classes.root}>
      {vehicle_stock_by_vehicle_no_loading && <CustomBackdropSpinner />}
      <GridItem xs={12} sm={12} md={12} style={{ margin: "0.5rem" }}>
        <Tooltip title="Back" arrow>
          <ArrowBackIcon
            fontSize="medium"
            onClick={() => window.history.back()}
            style={{ float: "left" }}
          />
          <Typography
            variant="h6"
            style={{ margin: "0.5rem", textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}
            color="black"
          >
            New Sale
          </Typography>
        </Tooltip>
      </GridItem>
      {renderVehicleDetailsUsingCards}
      {vehicleDetails &&
        vehicleDetails.vehicleNo &&
        vehicle_stock_by_vehicle_no_data &&
        vehicle_stock_by_vehicle_no_data.length > 0 && (
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Customer
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GridContainer spacing={2} alignItems="center" justify="center" style={{ margin: '0px', padding: '0px' }}>
                {!invoiceFormInputs.isCashSale && (<>
                  <Grid item xs={12} sm={9} md={9}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        console.log("Chosen Customer :==> ", value);
                        if (value) {
                          setAutoCompleteCustomer(value);
                          setSelectedCustomer(value.name);
                          prepareCustomerDetailsObject(value)
                          return;
                        }
                      }}
                      id="combo-box-products"
                      options={tempCustomers}
                      size="small"
                      clearOnBlur={true}
                      getOptionLabel={(option) => {
                        if (option) {
                          return `${option.name}`
                        }
                      }
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Customer Name"
                          size="small"
                          value={autoCompleteCustomer && autoCompleteCustomer.name ? autoCompleteCustomer.name : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Box>
                      <Link to="/admin/customer/signup" style={{
                        color: '#900C3F',
                        textDecoration: "none",
                        fontWeight: "600",
                        fontSize: "14px"
                      }}>
                        <Typography variant="button" color="#900C3F" style={{
                          fontWeight: "600",
                          fontSize: "14px"
                        }}>
                          Add Customer
                        </Typography>
                      </Link>
                    </Box>
                  </Grid>
                </>
                )}
                <Grid item xs={12} sm={6} md={6} lg={6} style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                  <FormControlLabel control={<Switch defaultChecked={invoiceFormInputs.isCashSale} onChange={handleIsCashFlowChange} />} label="is Cash Sale" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>

                  <TextField
                    label="Bill No"
                    variant="outlined"
                    size="small"
                    required
                    name="billNo"
                    onChange={handleInvoiceChange}
                    onBlur={checkIfDuplicateInvoice}
                    value={invoiceFormInputs.billNo}
                    type="string"
                  />
                  {errorObject && errorObject.errorMessage && (
                    <div variant="h5" style={{ textAlign: "center", fontSize: "1rem", fontWeight: "600", margin: "1rem", color: "red", justifyContent: "center", alignItems: "center" }}>
                      {errorObject.errorMessage}
                    </div>)}
                </Grid>
              </GridContainer>
            </AccordionDetails>
          </Accordion>
        )}
      {
        (invoiceFormInputs.isCashSale &&
          invoiceFormInputs.billNo ||
          (!invoiceFormInputs.isCashSale &&
            selectedCustomer && selectedCustomer.length > 0 && !errorObject)) &&
        (
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Product
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GridContainer spacing={2} alignItems="center" justify="center" style={{ margin: '0px', padding: '0px' }}>
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                  <Autocomplete
                    disablePortal
                    onChange={(event, value) => {
                      if (value && value.productName && value.productName.length > 0) {
                        handleClearProductForm()
                        setSelectedProduct(value);
                        showMaximumQuantityAllowed(value)
                        console.log('setSelectedProduct : ', value ? value.productName : '')
                      }
                    }}
                    id="combo-box-products"
                    options={customProducts}
                    size="small"
                    fullWidth
                    clearOnBlur={true}
                    style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}
                    getOptionLabel={(option) => {
                      return `${option.productName}`
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Product"
                        size="small"
                        value={productFormInputs.name ? productFormInputs.name : ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                  <TextField
                    label="Unit Of Messure"
                    placeholder="Unit Of Messure"
                    variant="outlined"
                    size="small"
                    required
                    name="uom"
                    disabled
                    type="text"
                    value={productFormInputs && productFormInputs.uom}
                  />
                </Grid>
                {productFormInputs.orderTypeSelected &&
                  productFormInputs.orderTypeSelected !== "" &&
                  productFormInputs.uom &&
                  productFormInputs.uom !== "" && (
                    <>
                      <Grid item xs={12} sm={6} md={6} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                        <TextField
                          label="Quantity"
                          variant="outlined"
                          size="small"
                          name="quantity"
                          required
                          onChange={handleQuantityChange}
                          type="number"
                          value={productFormInputs.quantity || null}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                        <TextField
                          style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}
                          label="Unit Price"
                          variant="outlined"
                          size="small"
                          name="unitPrice"
                          required
                          disabled
                          fullWidth
                          type="text"
                          value={productFormInputs.unitPrice || null}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                        <TextField
                          style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}
                          label="MRP"
                          variant="outlined"
                          size="small"
                          name="mrp"
                          required
                          disabled
                          fullWidth
                          type="text"
                          value={productFormInputs.mrp || null}
                        />
                      </Grid>

                      <Grid item xs={12} align="center">
                        <Typography
                          variant="body2"
                          style={{ margin: "0.5rem", textAlign: "center", fontWeight: "bold", verticalAlign: "middle", justifyContent: "center", alignItems: "center" }}
                          color="red"
                        >{maxQuantityAllowedErrorMessage}</Typography>
                      </Grid>
                      <Grid item xs={12} align="center">
                        <Button
                          size="small"
                          variant="contained"
                          type="submit"
                          color="secondary"
                          justify="center"
                          onClick={handleAddProduct}
                          disabled={!productFormInputs.isValidToAddToCart}
                          style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                        >
                          Add to Cart
                        </Button>
                      </Grid>
                    </>
                  )}
              </GridContainer>
            </AccordionDetails>
          </Accordion>
        )}
      {
        vehicleDetails &&
        vehicleDetails.vehicleNo && vehicleDetails.vehicleNo.length > 0 &&
        vehicle_stock_by_vehicle_no_data &&
        vehicle_stock_by_vehicle_no_data.length > 0 &&
        productsTobeAddedInInvoice && !errorObject && (<>
          <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              {renderContentUsingCards ? renderContentUsingCards : null}
            </GridItem>
          </GridContainer>
        </>
        )
      }
      { }
      {/** VEHICLE SALE PAYMENT START */}
      {
        productsTobeAddedInInvoice && productsTobeAddedInInvoice.length > 0 && customerDetailsObject && !errorObject && (
          <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <VehicleSalesPayment
                vehicleNo={vehicleDetails && vehicleDetails.vehicleNo ? vehicleDetails.vehicleNo : ""}
                customerDetails={customerDetailsObject}
                invoiceDetails={invoiceFormInputs}
                rowDataCartItems={productsTobeAddedInInvoice}
                productsInCart={productsTobeAddedInInvoice} >
              </VehicleSalesPayment>
            </GridItem>
          </GridContainer>
        )
      }
    </div >
  );
};

export default VehicleSalesInvoiceCreateScreen;
