import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Select,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
  Divider,
  Paper,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import DialogContent from "@mui/material/DialogContent";
import DeleteIcon from "@mui/icons-material/Delete";
import TuneIcon from "@mui/icons-material/Tune";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { PRODUCT_CREATE_RESET } from "../../constants/productConstants";

import {
  deleteProduct,
  listProductsByCategoryId,
  listProductsBySubCategoryId,
  updateProduct,
  listAllProducts,
} from "../../actions/productAction";
import { listCategories } from "../../actions/categoryAction";
import { listSubCategoriesByCategoryId } from "../../actions/subCategoryAction";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InputBase from "@mui/material/InputBase";
import Autocomplete from "@mui/material/Autocomplete";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const ProductListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  const gridRef = useRef();
  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  // const [openSnackBar, setOpenSnackBar] = React.useState(true);

  // const handleClickSnackbar = () => {
  //   setOpenSnackBar(true);
  // };
  const [autoCompleteProductValue, setAutoCompleteProductValue] = useState(() => { });
  const [productName, setProductName] = useState(() => "");
  // const handleCloseSnackBar = (event, reason) => {
  //   if (reason === 'clickaway')
  //     return;
  //   setOpenSnackBar(false);
  // };
  const vertical = 'top';
  const horizontal = 'center';
  let renderContentUsingAGGrid = "";
  let gridApi = "";
  const columnDefs = [
    { field: "#", resizable: true, suppressSizeToFit: true, width: 75, minWidth: 75, maxWidth: 75 ,cellStyle: { textAlign: "center" },},
    {
      field: "Name", headerName: 'Name', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "Id", headerName: 'Edit Price', resizable: true, suppressSizeToFit: true, width: 150, minWidth: 150, maxWidth: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer : (params) => (
        <span><Tooltip title="Edit Price" arrow>
          <TuneIcon
            size="small"
            variant="contained"
            type="submit"
            color="Gray"
            justify="center"
            onClick={() => handleDomestic(params.value)}
            style={{ width: "2rem", textAlign:"center", margin:"auto" }}
          ></TuneIcon>
        </Tooltip></span>
      ), 
      sortable: false,
      filter: false,
      floatingFilter: false
    },
    {
      field: "Description", headerName: 'Description', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "HsnCode", headerName: 'HSN Code', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "BrandName", headerName: 'Brand Name', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "Variety", headerName: 'Variety', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "ItemFrom", headerName: 'Item From', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "NetQuantity", headerName: 'Net Quantity', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "DietType", headerName: 'Diet Type', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "Manufactured", headerName: 'Manufactured', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "Manufacturer", headerName: 'Manufacturer', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "MarketedBy", headerName: 'Marketed By', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "ExpiryDate", headerName: 'Expiry Date', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "KeyFeatures", headerName: 'Key Features', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "MustKnowInformation", headerName: 'Must Know Information', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "Ingredients", headerName: 'Ingredients', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "Benefits", headerName: 'Benefits', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "CareInstructions", headerName: 'Care Instructions', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "MarketerAddress", headerName: 'Marketer Address', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "CustomerCareNumber", headerName: 'Customer care number', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "Disclaimer", headerName: 'Disclaimer', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "Edit", headerName: 'Edit', resizable: true, suppressSizeToFit: true, width: 100, minWidth: 100, maxWidth: 100,
      cellRenderer : (params) => (
        <span><Tooltip title="Edit SubCategory" arrow>
          <EditRoundedIcon
            size="small"
            variant="contained"
            type="submit"
            color="Gray"
            justify="center"
            onClick={() => handleEdit(params)}
            style={{ marginRight: "1rem", width: "2rem" }}
          ></EditRoundedIcon>
        </Tooltip></span>
      ), sortable: false,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "Delete", headerName: 'Delete', resizable: true, suppressSizeToFit: true, width: 100, minWidth: 100, maxWidth: 100,
      cellRenderer : (params) => (
        <span><Tooltip title="Delete" arrow>
          <DeleteRoundedIcon
            size="small"
            variant="contained"
            type="submit"
            color="Gray"
            justify="center"
            onClick={() => console.log("Deleting ", params.value)}
            style={{ marginRight: "1rem", width: "2rem" }}
          ></DeleteRoundedIcon>
        </Tooltip></span>
      ), sortable: false,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "center" },
    },
   
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const export_label = "Export > CSV";
  const allProducts = useSelector((state) => state.allProducts);
  const { all_products_loading, all_products } = allProducts;
  const [categorySelected, setCategorySelected] = useState(() => "");
  const [subCategorySelected, setSubCategorySelected] = useState(() => "");
  const [productSelected, setProductSelected] = useState(() => "");
  const [open, setOpen] = useState(() => false);
  const [confirmOpen, setConfirmOpen] = useState(() => false);
  const [filteredProduct, setFilteredProduct] = useState(() => { });
  const [action, setAction] = useState(() => { });
  const [idSelected, setIdSelected] = useState(() => {
    return "";
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listCategories());
    dispatch(listAllProducts());
  }, [dispatch]);

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  useEffect(() => {
    if(categorySelected && categorySelected !== ""){
      dispatch(listSubCategoriesByCategoryId(categorySelected));
    }
  } , [dispatch, categorySelected]);

  useEffect(() => {
    if(subCategorySelected && subCategorySelected !== ""){
    dispatch(listProductsBySubCategoryId(subCategorySelected));
    }
  }, [dispatch, subCategorySelected]);

  useEffect(() => {
    dispatch(listAllProducts());
  }, [dispatch]);

  useEffect(() => {
    console.log('filteredProduct : ', filteredProduct)
  }, [dispatch, filteredProduct]);

  const createHandler = (category) => {
    navigate("/admin/product/new");
  };
  const handleChangeCategory = (e) => {
    console.log("Category Changed  " + e.target.value);
    setCategorySelected(() => e.target.value);
    setSubCategorySelected(() => "");
  };
  const handleChangeSubCategory = (e) => {
    console.log("Sub Category Changed  " + e.target.value);
    setSubCategorySelected(() => e.target.value);
  };
  const nameChangeHandler = (nm) => {
    setFilteredProduct({ ...filteredProduct, name: nm });
  };

  const descChangeHandler = (dsc) => {
    setFilteredProduct({ ...filteredProduct, description: dsc });
  };
  const manufacturerChangeHandler = (mfr) => {
    setFilteredProduct({ ...filteredProduct, manufacturer: mfr });
  };

  const hsnCodeChangeHandler = (hc) => {
    setFilteredProduct({ ...filteredProduct, hsnCode: hc });
  };

  const brandNameChangeHandler = (bn) => {
    setFilteredProduct({ ...filteredProduct, brandName: bn });
  };

  const varietyChangeHandler = (vty) => {
    setFilteredProduct({ ...filteredProduct, variety: vty });
  };

  const itemFromChangeHandler = (itf) => {
    setFilteredProduct({ ...filteredProduct, itemFrom: itf });
  };
  const netQuantityChangeHandler = (nq) => {
    setFilteredProduct({ ...filteredProduct, netQuantity: nq });
  };
  const dietTypeChangeHandler = (dt) => {
    setFilteredProduct({ ...filteredProduct, dietType: dt });
  };
  const expiryDateChangeHandler = (ed) => {
    setFilteredProduct({ ...filteredProduct, expiryDate: ed });
  };
  const manufacturedChangeHandler = (mf) => {
    setFilteredProduct({ ...filteredProduct, manufactured: mf });
  };

  const keyFeaturesChangeHandler = (kf) => {
    setFilteredProduct({ ...filteredProduct, keyFeatures: kf });
  };
  const mustKnowInformationChangeHandler = (mki) => {
    setFilteredProduct({ ...filteredProduct, mustKnowInformation: mki });
  };
  const ingredientsChangeHandler = (ing) => {
    setFilteredProduct({ ...filteredProduct, ingredients  : ing });
  };

  const benefitsChangeHandler = (bfts) => {
    setFilteredProduct({ ...filteredProduct, benefits  : bfts });
  };

  const careInstructionsChangeHandler = (ci) => {
    setFilteredProduct({ ...filteredProduct, careInstructions  : ci });
  };
  const marketedByChangeHandler = (mb) => {
    setFilteredProduct({ ...filteredProduct, marketedBy  : mb });
  };

  const marketerAddressChangeHandler = (ma) => {
    setFilteredProduct({ ...filteredProduct, marketerAddress  : ma });
  };

  const customerCareNumberChangeHandler = (ccn) => {
    setFilteredProduct({ ...filteredProduct, customerCareNumber  : ccn });
  };

  const disclaimerChangeHandler = (dis) => {
    setFilteredProduct({ ...filteredProduct, disclaimer  : dis });
  };

  let renderProductsOptions = "";
  if (all_products && all_products.length > 0) {
    renderProductsOptions = all_products.map((eachProduct, idx) => {
      return (
        <MenuItem key={idx} value={eachProduct._id} style={{ color: "Gray" }}>
          {eachProduct.name}
        </MenuItem>
      );
    });
  }

  if (all_products && all_products.length > 0) {
    rowData = all_products.map((eachProduct, index) => {
      return {
        "#": index + 1,
        "Id": eachProduct._id,
        Name: eachProduct.name,
        Description: eachProduct.description,
        HsnCode: eachProduct.hsnCode,
        BrandName: eachProduct.brandName,
        Variety: eachProduct.variety,
        ItemFrom: eachProduct.itemFrom,
        NetQuantity: eachProduct.netQuantity,
        DietType: eachProduct.dietType,
        ExpiryDate: eachProduct.expiryDate,
        Manufactured: eachProduct.manufactured,
        Manufacturer: eachProduct.manufacturer,
        MarketedBy: eachProduct.marketedBy,
        MarketerAddress: eachProduct.marketerAddress,
        KeyFeatures: eachProduct.keyFeatures,
        MustKnowInformation: eachProduct.mustKnowInformation,
        Ingredients: eachProduct.ingredients,
        Benefits: eachProduct.benefits,
        CareInstructions: eachProduct.careInstructions,
        CustomerCareNumber: eachProduct.customerCareNumber,
        Disclaimer: eachProduct.disclaimer
      };
    });
    if (rowData) {
      renderContentUsingAGGrid = (
        <div
          className="ag-theme-quartz"
          style={{ width: "100%", height: "65vh" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
            // domLayout='autoHeight'
          ></AgGridReact>
        </div>
      );
    }
  }

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    product: createdProduct,
    error: errorCreate,
    success: successCreate,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });

    if (successCreate) {
      navigate("/admin/products");
    } else {
      dispatch(listProductsByCategoryId());
    }
  }, [
    dispatch,
    navigate,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
  ]);
  const handleDelete = (prd) => {
    console.log("handleDelete Exec..." + prd._id);
    setAction("delete");
    setConfirmOpen(true);
    setIdSelected(prd._id);
    console.log("ID SELECTED : " + prd._id);
  };

  const handleEdit = (params) => {
    console.log('params : ', params);
    setOpen(true);
    console.log("ID SELECTED : " + params.data.Id);
    setFilteredProduct(
      {
        _id: params.data.Id,
        name: params.data.Name,
        description: params.data.Description,
        stock: params.data.CountInStock,
        hsnCode: params.data.HsnCode,
        brandName: params.data.BrandName,
        variety: params.data.Variety,
        itemFrom: params.data.ItemFrom,
        netQuantity: params.data.NetQuantity,
        dietType: params.data.DietType,
        expiryDate: params.data.ExpiryDate,
        manufactured: params.data.Manufactured,
        manufacturer: params.data.Manufacturer,
        keyFeatures: params.data.KeyFeatures,
        mustKnowInformation: params.data.MustKnowInformation,
        ingredients: params.data.Ingredients,
        benefits: params.data.Benefits,
        careInstructions: params.data.CareInstructions,
        marketedBy: params.data.MarketedBy,
        marketerAddress: params.data.MarketerAddress,
        customerCareNumber: params.data.CustomerCareNumber,
        disclaimer: params.data.Disclaimer
      });
    setAction("edit");
  };
  const handleDomestic = (id) => {
    console.log('handleDomestic : ',id);
    console.log("Domestic :ProductID : " + id);
    navigate(`/admin/product/domestic/${id}`);
  };
  const handleBulk = (id) => {
    console.log('handleBulk : ',id);
    navigate(`/admin/product/bulk/${id}`);
  };

  const submitHandler = () => {
    console.log(" ####### EXEC submitHandler ####### : ProductList", action, filteredProduct);
    if (action === "edit") {
      console.log("filteredProduct :==> ", filteredProduct);
      // dispatch(updateProduct(filteredProduct._id, filteredProduct.name, filteredProduct.description,filteredProduct.countInStock,filteredProduct.isTaxable,filteredProduct.taxPercent,filteredProduct.isTaxable,filteredProduct.brand));
      dispatch(updateProduct(filteredProduct));
      setOpen(false);
      setFilteredProduct({});
    } else if (action === "delete") {
      console.log(filteredProduct);
      dispatch(deleteProduct(filteredProduct._id));
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      {loading && <Message variant="danger">{loading}</Message>}
      {loading && <CustomBackdropSpinner />}

      <GridContainer>
        {/* <Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar} key={vertical + horizontal}>
          <Alert onClose={handleCloseSnackBar} severity="info" sx={{ width: '100%' }}>
            Please Select Product to proceed!
          </Alert>
        </Snackbar> */}

        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Typography variant="h5" style={{ fontWeight: 500 }}>
                  Products List
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={createHandler}
                    style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "right", marginRight: "10px" }}
                    />
                    New Product
                  </Button>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
            xs={12}
            sm={12}
            md={12}
          >
            <Tooltip title="Export > Excel" arrow>
              <IosShareRoundedIcon fontSize="medium" style={{ float: "right", margin: "0.5rem" }} onClick={onExportClick} />
            </Tooltip>
          </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
              <Card>
                <CardHeader >
                  <Typography variant="h5" style={{ fontWeight: 500 }}>
                    Edit Product
                  </Typography>
                  <Divider />
                </CardHeader>
                <CardBody>
                  <form onSubmit={submitHandler}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Name"
                          label="Name"
                          variant="outlined"
                          name="name"
                          fullWidth
                          onChange={(e) => nameChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.name
                              ? filteredProduct.name
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Description"
                          label="Description"
                          variant="outlined"
                          name="description"
                          id="description"
                          onChange={(e) => descChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.description
                              ? filteredProduct.description
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="HsnCode"
                          label="Hsn Code *"
                          variant="outlined"
                          name="hsnCode"
                          id="hsnCode"
                          onChange={(e) => hsnCodeChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.hsnCode
                              ? filteredProduct.hsnCode
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Brand"
                          label="Brand *"
                          variant="outlined"
                          name="brandName"
                          id="brandName"
                          onChange={(e) => brandNameChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.brandName
                              ? filteredProduct.brandName
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Variety"
                          label="Variety *"
                          variant="outlined"
                          name="variety"
                          id="variety"
                          onChange={(e) => varietyChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.variety
                              ? filteredProduct.variety
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Item From"
                          label="Item From *"
                          variant="outlined"
                          name="itemFrom"
                          id="itemFrom"
                          onChange={(e) => itemFromChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.itemFrom
                              ? filteredProduct.itemFrom
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Net Quantity"
                          label="Net Quantity *"
                          variant="outlined"
                          name="netQuantity"
                          id="netQuantity"
                          onChange={(e) => netQuantityChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.netQuantity
                              ? filteredProduct.netQuantity
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Diet Type"
                          label="Diet Type *"
                          variant="outlined"
                          name="dietType"
                          id="dietType"
                          onChange={(e) => dietTypeChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.dietType
                              ? filteredProduct.dietType
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Manufactured"
                          label="Manufactured *"
                          variant="outlined"
                          name="manufactured"
                          id="manufactured"
                          onChange={(e) => manufacturedChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.manufactured
                              ? filteredProduct.manufactured
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Manufacturer"
                          label="Manufacturer *"
                          variant="outlined"
                          name="manufacturer"
                          id="manufacturer"
                          onChange={(e) => manufacturerChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.manufacturer
                              ? filteredProduct.manufacturer
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Expiry Date"
                          label="Expiry Date *"
                          variant="outlined"
                          name="expiryDate"
                          id="expiryDate"
                          onChange={(e) => expiryDateChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.expiryDate
                              ? filteredProduct.expiryDate
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Key Features"
                          label="Key Features *"
                          variant="outlined"
                          name="keyFeatures"
                          id="keyFeatures"
                          multiline
                          rows={4}
                          onChange={(e) => keyFeaturesChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.keyFeatures
                              ? filteredProduct.keyFeatures
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Must Know Information"
                          label="Must Know Information *"
                          variant="outlined"
                          name="mustKnowInformation"
                          id="mustKnowInformation"
                          multiline
                          rows={4}
                          onChange={(e) => mustKnowInformationChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.mustKnowInformation
                              ? filteredProduct.mustKnowInformation
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Ingredients"
                          label="Ingredients *"
                          variant="outlined"
                          name="ingredients"
                          id="ingredients"
                          multiline
                          rows={4}
                          onChange={(e) => ingredientsChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.ingredients
                              ? filteredProduct.ingredients
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Benefits"
                          label="Benefits *"
                          variant="outlined"
                          name="benefits"
                          id="benefits"
                          multiline
                          rows={4}
                          onChange={(e) => benefitsChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.benefits
                              ? filteredProduct.benefits
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Care Instructions"
                          label="Care Instructions *"
                          variant="outlined"
                          name="careInstructions"
                          id="careInstructions"
                          multiline
                          rows={4}
                          onChange={(e) => careInstructionsChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.careInstructions
                              ? filteredProduct.careInstructions
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Marketed By"
                          label="Marketed By *"
                          variant="outlined"
                          name="marketedBy"
                          id="marketedBy"
                          multiline
                          rows={4}
                          onChange={(e) => marketedByChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.marketedBy
                              ? filteredProduct.marketedBy
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Marketer Address"
                          label="Marketer Address *"
                          variant="outlined"
                          name="marketerAddress"
                          id="marketerAddress"
                          multiline
                          rows={4}
                          onChange={(e) => marketerAddressChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.marketerAddress
                              ? filteredProduct.marketerAddress
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Customer Care Number"
                          label="Customer Care Number *"
                          variant="outlined"
                          name="customerCareNumber"
                          id="customerCareNumber"
                          multiline
                          rows={4}
                          onChange={(e) => customerCareNumberChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.customerCareNumber
                              ? filteredProduct.customerCareNumber
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md ={6} >
                        <TextField
                          className={classes.inputText}
                          placeholder="Disclaimer"
                          label="Disclaimer *"
                          variant="outlined"
                          name="disclaimer"
                          id="disclaimer"
                          multiline
                          rows={4}
                          onChange={(e) => disclaimerChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredProduct && filteredProduct.disclaimer
                              ? filteredProduct.disclaimer
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} align="center">
                        <Button
                          size="small"
                          variant="contained"
                          type="submit"
                          color="secondary"
                          justify="center"
                          style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardBody>
              </Card>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ProductListScreen;
