import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Paper,
  TableCell,
  TableBody,
  TableRow,
  Divider,
  Tooltip,
  FormControl,
  InputLabel,
} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { listCategories } from "../../actions/categoryAction";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { createProduct } from "../../actions/productAction";
import { listSubCategoriesByCategoryId } from "../../actions/subCategoryAction";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  description: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  metrics: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  tax: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 50,
    },
  },
  hsnCode: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 150,
    },
  },
  brandName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  variety: {
    presence: { allowEmpty: true, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  itemFrom: {
    presence: { allowEmpty: true, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  netQuantity: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  dietType: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  manufactured: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  expiryDate: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  keyFeatures:{
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 2000,
    },
  },
  mustKnowInformation:{
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 2000,
    },
  },
  ingredients:{
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 2000,
    },
  },
  benefits:{
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 2000,
    },
  },
  careInstructions:{
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 2000,
    },
  },
  marketedBy:{
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 2000,
    },
  },
  marketerAddress:{
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 2000,
    },
  },
  customerCareNumber:{
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 2000,
    },
  },
  disclaimer:{
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 2000,
    },
  },
};

const ProductCreateForm = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categorySelected, setCategorySelected] = useState(() => "");
  const [subCategorySelected, setSubCategorySelected] = useState(() => "");

  const [imageUrl, setImageUrl] = useState(() => {
    return "";
  });
  const [isBestSeller, setIsBestSeller] = useState(() => {
    return false;
  });

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  const productCreate = useSelector((state) => state.productCreate);
  const { loading, error, product, product_create_success } = productCreate;

  const categoryList = useSelector((state) => state.categoryList);
  const { categories } = categoryList;

  useEffect(() => {
    dispatch(listSubCategoriesByCategoryId(categorySelected));
  }, [dispatch, categorySelected]);

  const subCategoriesByCategory = useSelector(
    (state) => state.subCategoryListByCategory
  );
  const { subcategories } = subCategoriesByCategory;

  let cats = [];
  if (categories) cats = categories ? categories.categories : [];

  let renderCategoriesOptions = "";
  if (cats && cats.length > 0) {
    renderCategoriesOptions = cats.map((eachCategory, idx) => {
      return (
        <MenuItem key={idx} value={eachCategory._id}>
          {eachCategory.name}
        </MenuItem>
      );
    });
  }

  let renderSubCategoriesOptions = "";
  if (subcategories && subcategories.length > 0) {
    renderSubCategoriesOptions = subcategories.map((eachSubCategory, idx) => {
      return (
        <MenuItem key={idx} value={eachSubCategory._id}>
          {eachSubCategory.name}
        </MenuItem>
      );
    });
  }
  if (product_create_success) {
    console.log("Success Response to redirecting to Products List");
    navigate("/admin/products");
  }

  const handleChangeCategory = (e) => {
    console.log("Category Changed  " + e.target.value);
    setCategorySelected(() => e.target.value);
    setSubCategorySelected(() => "");
  };

  const handleChangeSubCategory = (e) => {
    console.log("Sub Category Changed  " + e.target.value);
    setSubCategorySelected(() => e.target.value);
  };

  const handleIsBestSellerChange = (e) => {
    e.preventDefault();
    setIsBestSeller(e.target.checked);
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("EXEC handleSubmit()...");
    console.log(formState.values, subCategorySelected);

    if (formState.isValid) {
      console.log(
        "Form State is Valid hence proceeding to Product Action", formState,
        formState.values,
        subCategorySelected,
        isBestSeller
      );
      dispatch(
        createProduct(
          formState.values.name,
          formState.values.description,
          formState.values.metrics,
          formState.values.tax,
          subCategorySelected,
          isBestSeller,
          "dummyImageURL",
          // "DUMMY PO ID",
          formState.values.hsnCode,
          formState.values.brandName,
          formState.values.variety,
          formState.values.itemFrom,
          formState.values.netQuantity,
          formState.values.dietType,
          formState.values.expiryDate,
          formState.values.manufactured,
          formState.values.manufacturer,
          formState.values.keyFeatures,
          formState.values.mustKnowInformation,
          formState.values.ingredients,
          formState.values.benefits,
          formState.values.careInstructions,
          formState.values.marketedBy,
          formState.values.marketerAddress,
          formState.values.customerCareNumber,
          formState.values.disclaimer,
        )
      );
    } else
      console.log(
        "Form State is NOT VALID hence Doig Nothing", formState,
        formState.values,
        subCategorySelected,
        isBestSeller,
      );

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <GridContainer spacing={1} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} style={{textAlign:"center", justifyContent:"center",alignItems:"center"}}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        label="Category"
                        value={categorySelected}
                        onChange={handleChangeCategory}
                        placeholder="Category"
                        style={{ width: "16rem" }}
                        size="small"
                        variant="outlined"
                        required
                      >
                        {renderCategoriesOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} style={{textAlign:"center", justifyContent:"center",alignItems:"center"}}>
                    <FormControl >
                      <InputLabel id="demo-simple-select-label">
                        SubCategory
                      </InputLabel>
                      <Select
                        label="SubCategory"
                        value={subCategorySelected}
                        onChange={handleChangeSubCategory}
                        placeholder="SubCategory"
                        style={{ width: "16rem" }}
                        size="small"
                        variant="outlined"
                        required
                      >
                        {renderSubCategoriesOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardBody>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Name"
                      label="Name*"
                      variant="outlined"
                      size="small"
                      name="name"
                      fullWidth
                      helperText={
                        hasError("name") ? formState.errors.name[0] : null
                      }
                      error={hasError("name")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.name || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Description"
                      label="Description *"
                      variant="outlined"
                      size="small"
                      name="description"
                      fullWidth
                      helperText={
                        hasError("description")
                          ? formState.errors.description[0]
                          : null
                      }
                      error={hasError("description")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.description || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Metrics"
                      label="Metrics *"
                      variant="outlined"
                      size="small"
                      name="metrics"
                      fullWidth
                      helperText={
                        hasError("metrics")
                          ? formState.errors.metrics[0]
                          : null
                      }
                      error={hasError("metrics")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.metrics || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Tax"
                      label="Tax *"
                      variant="outlined"
                      size="small"
                      name="tax"
                      fullWidth
                      // helperText={
                      //   hasError("tax")
                      //     ? formState.errors.tax[0]
                      //     : null
                      // }
                      // error={hasError("tax")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.tax || null}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Brand"
                      label="brandName *"
                      variant="outlined"
                      size="small"
                      name="brandName"
                      fullWidth
                      helperText={
                        hasError("brandName")
                          ? formState.errors.brandName[0]
                          : null
                      }
                      error={hasError("brandName")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.brandName || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="HSN Code"
                      label="HSN Code *"
                      variant="outlined"
                      size="small"
                      name="hsnCode"
                      fullWidth
                      helperText={
                        hasError("hsnCode")
                          ? formState.errors.hsnCode[0]
                          : null
                      }
                      error={hasError("hsnCode")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.hsnCode || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="variety"
                      label="variety *"
                      variant="outlined"
                      size="small"
                      name="variety"
                      fullWidth
                      helperText={
                        hasError("variety")
                          ? formState.errors.variety[0]
                          : null
                      }
                      error={hasError("variety")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.variety || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Item From"
                      label="Item From *"
                      variant="outlined"
                      size="small"
                      name="itemFrom"
                      fullWidth
                      helperText={
                        hasError("itemFrom")
                          ? formState.errors.itemFrom[0]
                          : null
                      }
                      error={hasError("itemFrom")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.itemFrom || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Net Quantity"
                      label="Net Quantity *"
                      variant="outlined"
                      size="small"
                      name="netQuantity"
                      fullWidth
                      helperText={
                        hasError("netQuantity")
                          ? formState.errors.netQuantity[0]
                          : null
                      }
                      error={hasError("netQuantity")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.netQuantity || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Diet Type"
                      label="Diet Type *"
                      variant="outlined"
                      size="small"
                      name="dietType"
                      fullWidth
                      helperText={
                        hasError("dietType")
                          ? formState.errors.dietType[0]
                          : null
                      }
                      error={hasError("dietType")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.dietType || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Manufactured"
                      label="Manufactured *"
                      variant="outlined"
                      size="small"
                      name="manufactured"
                      fullWidth
                      helperText={
                        hasError("manufactured")
                          ? formState.errors.manufactured[0]
                          : null
                      }
                      error={hasError("manufactured")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.manufactured || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Manufacturer"
                      label="Manufacturer *"
                      variant="outlined"
                      size="small"
                      name="manufacturer"
                      fullWidth
                      helperText={
                        hasError("manufacturer")
                          ? formState.errors.manufacturer[0]
                          : null
                      }
                      error={hasError("manufacturer")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.manufacturer || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Use by"
                      label="Use by *"
                      variant="outlined"
                      size="small"
                      name="expiryDate"
                      fullWidth
                      helperText={
                        hasError("expiryDate")
                          ? formState.errors.expiryDate[0]
                          : null
                      }
                      error={hasError("expiryDate")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.expiryDate || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isBestSeller}
                            onChange={handleIsBestSellerChange}
                            inputProps={{ "aria-label": "controlled" }}
                            value={isBestSeller}
                          />
                        }
                        label="Is Best Seller?"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Customer Care Number"
                      label="Customer Care Number*"
                      variant="outlined"
                      size="small"
                      name="customerCareNumber"
                      fullWidth
                      multiline
                      rows={4}
                      helperText={
                        hasError("customerCareNumber")
                          ? formState.errors.customerCareNumber[0]
                          : null
                      }
                      error={hasError("customerCareNumber")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.customerCareNumber || ""}
                    />
                  </Grid>   
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Key Features"
                      label="Key Features *"
                      variant="outlined"
                      size="small"
                      name="keyFeatures"
                      fullWidth
                      multiline
                      rows={4}
                      helperText={
                        hasError("keyFeatures")
                          ? formState.errors.keyFeatures[0]
                          : null
                      }
                      error={hasError("keyFeatures")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.keyFeatures || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Must Know Information"
                      label="Must Know Information *"
                      variant="outlined"
                      size="small"
                      name="mustKnowInformation"
                      fullWidth
                      multiline
                      rows={4}
                      helperText={
                        hasError("mustKnowInformation")
                          ? formState.errors.mustKnowInformation[0]
                          : null
                      }
                      error={hasError("mustKnowInformation")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.mustKnowInformation || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Ingredients"
                      label="Ingredients*"
                      variant="outlined"
                      size="small"
                      name="ingredients"
                      fullWidth
                      multiline
                      rows={4}
                      helperText={
                        hasError("ingredients")
                          ? formState.errors.ingredients[0]
                          : null
                      }
                      error={hasError("ingredients")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.ingredients || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Benefits"
                      label="Benefits*"
                      variant="outlined"
                      size="small"
                      name="benefits"
                      fullWidth
                      multiline
                      rows={4}
                      helperText={
                        hasError("benefits")
                          ? formState.errors.benefits[0]
                          : null
                      }
                      error={hasError("benefits")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.benefits || ""}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Care Instructions"
                      label="Care Instructions*"
                      variant="outlined"
                      size="small"
                      name="careInstructions"
                      fullWidth
                      multiline
                      rows={4}
                      helperText={
                        hasError("careInstructions")
                          ? formState.errors.careInstructions[0]
                          : null
                      }
                      error={hasError("careInstructions")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.careInstructions || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Marketed By"
                      label="Marketed By*"
                      variant="outlined"
                      size="small"
                      name="marketedBy"
                      fullWidth
                      multiline
                      rows={4}
                      helperText={
                        hasError("marketedBy")
                          ? formState.errors.marketedBy[0]
                          : null
                      }
                      error={hasError("marketedBy")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.marketedBy || ""}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Marketer Address"
                      label="Marketer Address*"
                      variant="outlined"
                      size="small"
                      name="marketerAddress"
                      fullWidth
                      multiline
                      rows={4}
                      helperText={
                        hasError("marketerAddress")
                          ? formState.errors.marketerAddress[0]
                          : null
                      }
                      error={hasError("marketerAddress")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.marketerAddress || ""}
                    />
                  </Grid>  
                 
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Disclaimer"
                      label="Disclaimer*"
                      variant="outlined"
                      size="small"
                      name="disclaimer"
                      fullWidth
                      multiline
                      rows={4}
                      helperText={
                        hasError("disclaimer")
                          ? formState.errors.disclaimer[0]
                          : null
                      }
                      error={hasError("disclaimer")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.disclaimer || ""}
                    />
                  </Grid>                   
                  <Grid item xs={12} align="center">
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      color="secondary"
                      justify="center"
                      style={{
                        textTransform: "capitalize",
                        width: "20vh",
                        borderRadius: "5px",
                      }}
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};

export default ProductCreateForm;
