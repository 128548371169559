import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Button, Switch, Box, Tooltip, Paper } from "@mui/material";
import GridContainer from "../Grid/GridContainer";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../assets/images/green_tick.png";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { listAllProducts, updateProductsVisibility } from "../../actions/productAction";
import Message from "../Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import { PRODUCT_VISIBILITY_UPDATE_RESET } from "../../constants/productConstants";

const ProductsVisibility = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allProducts = useSelector((state) => state.allProducts);
  const { all_products_loading, all_products, all_products_error } = allProducts;

  const productsVisibilityUpdate = useSelector((state) => state.productsVisibilityUpdate);
  const { products_visibility_update_loading, products_visibility_update_success, products_visibility_update_error } = productsVisibilityUpdate;
  const [rowData, setRowData] = useState([]);
  const [updatedRecords, setUpdatedRecords] = useState([]); // Tracks updated records
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);
  const columnDefs = useMemo(
    () => [
      {
        field: "#",
        headerName: "#",
        resizable: true,
        width: 75,
        minWidth: 75,
        maxWidth: 75,
        cellStyle: { textAlign: "center" },
        valueGetter: (params) => params.node.rowIndex + 1,
      },
      {
        field: "name",
        headerName: "Name",
        resizable: true,
        flex: 1,
        minWidth: 300,
      },
      {
        field: "isVisible",
        headerName: "Visibility",
        minWidth: 150,
        maxWidth: 200,
        cellStyle: { textAlign: "center" },
        cellRenderer: (params) => (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Switch
              checked={params.data.isVisible}
              onChange={(e) => handleVisibilityToggle(params.data.id, e.target.checked)}
              color="black"
            />
            {params.data.isVisible ? (
              <Tooltip title="Show"><VisibilityIcon style={{ marginLeft: 8 }} /></Tooltip>
            ) : (
              <Tooltip title="Hide"><VisibilityOffIcon style={{ marginLeft: 8 }} /></Tooltip>
            )}
          </Box>
        ),
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
    }),
    []
  );

  const handleClose = () => {
    setOpen(false);
    if (products_visibility_update_success) {
      dispatch({ type: PRODUCT_VISIBILITY_UPDATE_RESET });
      navigate("/admin/products-visibility");
    }
  };

  useEffect(() => {
    dispatch(listAllProducts());
  }, [dispatch]);

  useEffect(() => {
    if (all_products && all_products.length > 0) {
      setRowData(
        all_products.map((product) => ({
          id: product._id,
          name: product.name,
          isVisible: product.isVisible ?? true, // Default to true if not set
        }))
      );
    }
  }, [all_products]);

  useEffect(() => {
    console.log("useEffect Getting Called on Products Visibility Screen ", products_visibility_update_success);
    if (products_visibility_update_success) {
        console.log(
            "products_visibility_update_success :==> ",
            products_visibility_update_success, " Dispatching to RESET on Close of Dialog"
        );
        setOpen(() => true);
        setUpdatedRecords(()=>[])
    }
}, [products_visibility_update_success]);

  const handleVisibilityToggle = (id, isVisible) => {
    setRowData((prevData) =>
      prevData.map((product) => {
        if (product.id === id) {
          const updatedProduct = { ...product, isVisible };
          // Add or update the product in updatedRecords
          setUpdatedRecords((prevUpdated) => {
            const existingIndex = prevUpdated.findIndex((item) => item.id === id);
            if (existingIndex > -1) {
              prevUpdated[existingIndex] = updatedProduct;
              return [...prevUpdated];
            } else {
              return [...prevUpdated, updatedProduct];
            }
          });
          return updatedProduct;
        }
        return product;
      })
    );
  };

  const handleSave = () => {
    console.log("Updated Records:", updatedRecords);
    console.log("Now Dispatching an action to save updatedRecords to the backend here")
    dispatch(updateProductsVisibility(updatedRecords))
  };

  return (
    <React.Fragment>
      {all_products_error && <Message variant="danger">{all_products_error}</Message>}
      {all_products_loading && <CustomBackdropSpinner />}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer spacing={2} alignItems="center" justify="center">
            <Grid item xs={12} >
              <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                  <img
                    alt="Order Placed"
                    src={greeTickImage}
                    style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                  />
                </div>
                <Typography
                  variant="h5"
                  color="black"
                  style={{
                    margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                  }}
                >
                  Successfully Changed the Visibility of Product(s)
                </Typography>
              </Paper>
            </Grid>
          </GridContainer>
        </DialogContent>
      </BootstrapDialog>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" gutterBottom>
            Configure Product Visibility
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div
            className="ag-theme-quartz"
            style={{ height: "65vh", width: "80%", marginLeft: "auto", marginRight: "auto" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={20}
              rowSelection="multiple"
            />
          </div>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled = {updatedRecords && updatedRecords.length >0 ? false : true  }
            color="secondary"
            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
          >
            Appy & Save
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProductsVisibility;
